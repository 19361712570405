import { Observable } from 'rxjs';

export function getProp<T, Key extends keyof T>(key: Key) {
  return function(source$: Observable<T>): Observable<T[Key]> {
    return new Observable(subscriber => {
      return source$.subscribe({
        next(value) { subscriber.next(value[key]); },
        error(error: unknown) { subscriber.error(error); },
        complete() { subscriber.complete(); }
      });
    });
  };
}
