import { AbstractControl } from '@angular/forms';

type PathTreeNode = {
  children: Map<string, PathTreeNode>;
  builder?: () => AbstractControl;
};

export class ArrayEntryBuilderManager {

  tree: PathTreeNode;

  constructor() {
    this.tree = { children: new Map() };
  }

  getBuilder(path?: string): (() => AbstractControl) | undefined {
    return this.getNodeForPath(path)?.builder;
  }

  hasBuilder(path?: string): boolean {
    return !!this.getBuilder(path);
  }

  registerBuilder(path: string, builder: () => AbstractControl): void {
    const parts = path.split('.');

    let currentNode = this.tree;
    for (const part of parts) {

      const pathNeedsToBeCreated = !currentNode.children.has(part);
      if (pathNeedsToBeCreated) {
        currentNode.children.set(part, this.newEmptyNode());
      }

      currentNode = currentNode.children.get(part) as PathTreeNode;
    }

    currentNode.builder = builder;
  }

  private getNodeForPath(path?: string): PathTreeNode | null {
    if (!path) {
      return this.tree;
    }

    const parts = path.split('.');

    let currentNode: PathTreeNode = this.tree;
    for (const part of parts) {
      const pathExists = currentNode.children.has(part);
      if (!pathExists) {
        return null;
      }

      currentNode = currentNode.children.get(part) as PathTreeNode;
    }

    return currentNode;
  }

  private newEmptyNode(): PathTreeNode {
    return { children: new Map<string, PathTreeNode>() };
  }

}
