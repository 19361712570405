import { AbstractControl, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ControlSnapshot } from './control-snapshot';
import { Form } from './form';
import { FormsMap } from '../utils/forms-map';
import { TypedForm } from './typed-form';
import { TypedFormArray } from './typed-form-array';
import { isEqual } from '../utils/is-equal';

export class TypedFormCollection<CollectionValue = any, CollectionType = any> implements Form<CollectionValue> {

  readonly submittable$: Observable<boolean>;
  readonly destroy$: Observable<keyof CollectionType>;

  private readonly _initialValue$: BehaviorSubject<CollectionValue | null> = new BehaviorSubject(null as CollectionValue | null);

  private readonly collectionInitialValueChanged$: Observable<boolean>;
  private readonly collectionValidityChanges$: Observable<boolean>;
  private readonly collectionValueChanges$: Observable<CollectionValue>;
  private readonly collectionErrorsChanges$: Observable<any>;

  private readonly _destroy$ = new Subject<keyof CollectionType>();
  private autoDestroySubscription?: Subscription;

  private readonly initializedForms$: Observable<Array<TypedForm>>;

  get initialValue(): CollectionValue | null {
    return this._initialValue$.getValue();
  }

  constructor(
    readonly name: keyof CollectionType,
    private readonly formNames: Array<keyof CollectionType>,
    private readonly formsMap: FormsMap<CollectionType>,
    containsInitialValue: boolean
  ) {
    this.initializedForms$ = this.buildInitializedForms$();
    this.destroy$ = this._destroy$.asObservable();

    this.collectionValidityChanges$ = this.buildCollectionValidityChanges$(this.initializedForms$);
    this.collectionErrorsChanges$ = this.buildCollectionErrorsChanges$(this.initializedForms$);
    this.collectionValueChanges$ = this.buildCollectionValueChanges$(this.initializedForms$);
    this.collectionInitialValueChanged$ = this.buildCollectionInitialValueChanged$();

    this.submittable$ = this.buildSubmittable$(
      this.collectionValidityChanges$,
      this.collectionInitialValueChanged$
    );

    if (containsInitialValue) {
      this.setInitialValue();
    }
  }

  private buildInitializedForms$(): Observable<Array<TypedForm>> {
    return this.formsMap.forms$.pipe(
      map(forms => forms.filter(({ name }) => this.formNames.includes(name as any)))
    );
  }

  private buildSubmittable$(validityChanges$: Observable<boolean>, initialValueChanged$: Observable<boolean>): Observable<boolean> {
    return combineLatest([
      validityChanges$,
      initialValueChanged$
    ]).pipe(
      map(([valid, changed]) => valid && changed)
    );
  }

  private buildCollectionValidityChanges$(initializedForms$: Observable<Array<TypedForm>>): Observable<boolean> {
    return initializedForms$.pipe(
      map(initializedForms => initializedForms.map(form => form.validityChanges$())),
      switchMap(validities => combineLatest(validities)),
      map(validities => validities.every(Boolean))
    );
  }

  private buildCollectionErrorsChanges$(initializedForms$: Observable<Array<TypedForm>>): Observable<any> {
    return initializedForms$.pipe(
      map(initializedForms => initializedForms.map(form => form.errorsChanges$().pipe(
        map(errors => ({ form: form.name, errors }))
      ))),
      switchMap(formErrorPairs => combineLatest(formErrorPairs)),
      map(formErrorPairs => Object.assign({}, ...formErrorPairs))
    );
  }

  private buildCollectionValueChanges$(initializedForms$: Observable<Array<TypedForm>>): Observable<CollectionValue> {
    return initializedForms$.pipe(
      map(initializedForms => initializedForms.map(form => form.valueChanges$())),
      switchMap(valueChanges => combineLatest(valueChanges)),
      map(valueChanges => Object.assign({}, ...valueChanges))
    );
  }

  private buildCollectionInitialValueChanged$(): Observable<boolean> {
    return combineLatest([
      this.valueChanges$(),
      this._initialValue$
    ]).pipe(
      map(([currentValue, initialValue]) => !isEqual(currentValue, initialValue))
    );
  }

  /**
   * Returns the validity of the form.
   * If no control exists under the given path, `null` is returned.
   * @see TypedForm#isValid
   * @example
   * // validity of the form
   * collection.isValid();
   */
  isValid(): boolean;
  /**
   * Returns the validity of a control within the form.
   * If no control exists under the given path, `null` is returned.
   * @see TypedForm#isValid
   * @example
   * // validity of a control within the collection
   * collection.isValid('email');
   * collection.isValid('notiz.content');
   */
  isValid(path: string): boolean;
  isValid(path?: string): boolean {
    if (!path) {
      return !this.formNames
        .map(formName => this.getForm(formName) as any)
        .some(form => !form.isValid());
    }

    const control = this.getOrThrowError(path);
    return control.valid;
  }

  /**
   * Takes a snapshot of the collection.
   * NOTE: This is done by combining the snapshots of all individual forms.
   * @see ControlSnapshot
   * @example
   * // gets a snapshot of the collection
   * const snapshot = collection.snapshot();
   */
  snapshot<T = CollectionValue>(): ControlSnapshot<T>;
  /**
   * Takes a snapshot of a control within the collection.
   * @see ControlSnapshot
   * @example
   * // gets a controls snapshot
   * const snapshot = collection.snapshot('email');
   */
  snapshot<T = CollectionValue>(path: string): ControlSnapshot<T>;
  snapshot<T = CollectionValue>(path?: string): ControlSnapshot<T> {
    if (!path) {
      const snapshots: Array<ControlSnapshot> = this.formNames
        .map(formName => this.getForm(formName) as any)
        .map(form => form.snapshot());

      return this.combineSnapshots(snapshots);
    }

    const form = this.getFormThatHasControlOrError(path);
    return form.snapshot(path);
  }

  private combineSnapshots<T>(snapshots: Array<ControlSnapshot>): ControlSnapshot<T> {
    const formsValid = !snapshots.some(({ valid }) => !valid);

    return {
      disabled: false,
      valid: formsValid,
      invalid: !formsValid,
      touched: !snapshots.some(({ touched }) => !touched),
      pristine: !snapshots.some(({ pristine }) => !pristine),
      errors: Object.assign({}, ...snapshots.map(({ errors }) => errors)),
      value: Object.assign({}, ...snapshots.map(({ value }) => value)) as T,
      rawValue: Object.assign({}, ...snapshots.map(({ rawValue }) => rawValue)) as T,
      pending: !snapshots.some(({ pending }) => !pending),
      dirty: !snapshots.some(({ dirty }) => !dirty),
      controls: Object.assign({}, ...snapshots.map(({ controls }) => controls))
    };
  }

  /**
   * Returns the value of the collection.
   * NOTE: This is done by combining the values of all forms.
   * NOTE: Due to issues with Angulars poor forms implementation and a conflict with our backend,
   *       **the forms value is transformed before being returned**.
   *       This results in differences between {@link value}, {@link valueChanges$} and {@link snapshot}.
   *       This is the current design and might change in the future.
   * @see AbstractControl#value
   * @see FormEvaluator#value
   * @example
   * // value of the form
   * collection.value();
   */
  value(): CollectionValue;
  /**
   * Returns the value of a control within the form.
   * If no control exists under the given path, `null` is returned.
   * NOTE: This is done by combining the values of all forms.
   * NOTE: Due to issues with Angulars poor forms implementation and a conflict with our backend,
   *       **the forms value is transformed before being returned**.
   *       This results in differences between {@link value}, {@link valueChanges$} and {@link snapshot}.
   *       This is the current design and might change in the future.
   * @see AbstractControl#value
   * @see FormEvaluator#value
   * @example
   * // value of a control within the form
   * collection.value('email');
   *
   * // values can also be typed
   * collection.value<number>('plz');
   */
  value<R = any>(path: string): R;
  value<R = CollectionValue>(path?: string): R {
    if (!path) {
      const formValues = this.formNames.map(formName => this.getForm(formName)?.value());
      return Object.assign({}, ...formValues);
    }

    return this.get(path)?.value;
  }

  /**
   * Emits the current dirty state of the collection.
   * This is `false` whenever the collections value is equal to the collections initial value
   * and `true` otherwise.
   * NOTE: This is done by combining the dirty states of all forms.
   * NOTE: This will only emit values, when {@link setInitialValue} was called before!
   * @see setInitialValue
   * @see TypedForm#initialValueChanged$
   * @example
   * // emits the dirty state of the collection
   * collection.initialValueChanged$().subscribe(changed => { ... });
   */
  initialValueChanged$(): Observable<boolean>;
  /**
   * Emits the current dirty state of a control within the collection.
   * This is `false` whenever the controls value is equal to the controls initial value
   * and `true` otherwise.
   * NOTE: This will only emit values, when {@link setInitialValue} was called before!
   * @see setInitialValue
   * @see TypedForm#initialValueChanged$
   * @example
   * // emits the dirty state of a control within the collection
   * collection.initialValueChanged$('email').subscribe(changed => { ... });
   */
  initialValueChanged$(path: string): Observable<boolean>;
  initialValueChanged$(path?: string): Observable<boolean> {
    if (!path) {
      return this.collectionInitialValueChanged$;
    }

    const form = this.getFormThatHasControlOrError(path);
    return form.initialValueChanged$(path);
  }

  /**
   * Emits the current validity of the form.
   * @example
   * // emits the validity of the form
   * collection.validityChanges$().subscribe(changed => { ... });
   */
  validityChanges$(): Observable<boolean>;
  /**
   * Emits the current validity of a control within the form.
   * @example
   * // emits the validity of the control within the collection
   * collection.validityChanges$('email').subscribe(changed => { ... });
   */
  validityChanges$(path: string): Observable<boolean>;
  validityChanges$(path?: string): Observable<boolean> {
    if (!path) {
      return this.collectionValidityChanges$;
    }

    const form = this.getFormThatHasControlOrError(path);
    return form.validityChanges$(path);
  }

  /**
   * Emits the value of the form.
   * NOTE: This will emit the *actual* value of a form.
   *           Values emitted are NOT transformed by a FormEvaluator.
   *           (meaning empty strings are not replaced by null)
   * NOTE: This is done by combining all forms values and observables.
   * @see AbstractControl#valueChanges
   * @example
   * // emits the value of the form
   * form.valueChanges$().subscribe(changed => { ... });
   */
  valueChanges$<T = CollectionValue>(): Observable<T>;
  /**
   * Emits the value of a control within the form.
   * NOTE: This will emit the *actual* value of a form.
   *           Values emitted are NOT transformed by a FormEvaluator.
   *           (meaning empty strings are not replaced by null)
   * @see AbstractControl#valueChanges
   * @example
   * // emits the value of a control within the form
   * form.valueChanges$('email').subscribe(changed => { ... });
   */
  valueChanges$<T = CollectionValue>(path?: string): Observable<T>;
  valueChanges$<T = CollectionValue>(path?: string): Observable<T> {
    if (!path) {
      return this.collectionValueChanges$ as any;
    }

    const form = this.getFormThatHasControlOrError(path);
    return form.valueChanges$(path);
  }

  /**
   * Emits the `errors`-object of the collection.
   * @see AbstractControl#errors
   * @example
   * // emits the errors of the form
   * formsManager.errorsChanges$().subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(): Observable<Errors>;
  /**
   * Emits the `errors`-object of a control within the form.
   * @see AbstractControl#errors
   * @example
   * // emits the errors of a control within a form
   * form.errorsChanges$('email').subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(path: string): Observable<Errors>;
  errorsChanges$<Errors = any>(path?: string): Observable<Errors>{
    if (!path) {
      return this.collectionErrorsChanges$;
    }

    const form = this.getFormThatHasControl(path);
    if (!form) {
      throw Error('No form matching path!');
    }
    
    return form.errorsChanges$(path);
  }

  private getFormThatHasControl(path: string): TypedForm | null {
    return this.formNames
      .map(formName => this.getForm(formName))
      .map(form => ({ form, control: form?.get(path) }))
      .find(({ control }) => !!control)
      ?.form
      ?? null;
  }

  private getFormThatHasControlOrError(path: string): TypedForm {
    const form = this.getFormThatHasControl(path);
    if (!form) {
      throw Error('No form matching path!');
    }

    return form;
  }

  /**
   * Gets a specific form from the collection.
   * If no form with the given name exists within the collection, `undefined` is returned.
   * @see TypedForm
   * @example
   * // gets a form from the collection
   * collection.getForm('contact-form');
   */
  getForm<T extends keyof CollectionType>(name: T): TypedForm<CollectionType[T]> | null {
    return this.formNames.includes(name) ? this.formsMap.getForm(name) : null;
  }

  /**
   * Checks if a form with the given name exists within the collection.
   * Returns `true` if a form with the given name exists within the collection
   * and `false` otherwise.
   * @see TypedForm
   * @example
   * // checks if a form exists within the collection
   * collection.hasForm('contact-form');
   */
  hasForm<T extends keyof CollectionType>(name: T): boolean {
    return !!this.getForm(name);
  }

  /**
   * Gets a FormArray from the collection and wraps it within a typed wrapper.
   * If no control was registered under the given path, an error is thrown.
   * @see TypedFormArray
   * @example
   * // gets a FormArray from the collection and wraps it for convenience
   * const adressen = collection.getArray('adressen');
   */
  getArray<T = any>(path: string): TypedFormArray<T> | null {
    const formContainingArray: TypedForm | null = this.getFormThatHasControl(path);
    return formContainingArray ? new TypedFormArray<T>(path, formContainingArray as any) : null;
  }

  /**
   * Gets a specific control from the collection.
   * If no control was registered under the given path, `null` is returned.
   * @see TypedForm#get
   * @example
   * // gets a control within the collection
   * collection.get('contact-form', 'email');
   */
  get<R extends AbstractControl = AbstractControl>(path: string): R | null {
    for (const formName of this.formNames) {
      const control = this.getForm(formName)?.get(path);

      if (control) {
        return control as R;
      }
    }

    return null;
  }

  private getOrThrowError<T extends AbstractControl = AbstractControl>(path: string): T {
    const control = this.get(path);

    if (!control) {
      throw Error(`There is no control registered unter any path "${ path }"!`);
    }

    return control as T;
  }

  /**
   * Checks if the collection has a control registered under a given path.
   * Returns `true` if a control is registered under that path
   * and `false` otherwise.
   * @example
   * // checks if a control exists within the collection
   * collection.hasControl('email');
   */
  has(path: string): boolean {
    return this.formNames
      .map(formName => this.getForm(formName))
      .map(form => form?.get(path))
      .some(result => !!result);
  }

  /**
   * Sets the initial value of the collection using the collections current value.
   * This initial value is used as reference during the change detection.
   * @see initialValueChanged$
   * @example
   * // sets the collections initial value that is used as a baseline during change-detection
   * collection.setValue({ email: '...' });
   *
   * formsManager.setInitialValue();
   * // initialValue is now `{ email: '...' }`
   */
  setInitialValue(): TypedFormCollection<CollectionValue, CollectionType> {
    this._initialValue$.next(this.value());

    return this;
  }

  /**
   * Sets the value of the collection.
   * To ensure that the form is filled correctly the value for each form is mapped
   * to its according form name.
   * This is done analogously to the Angular implementation (for every individual form).
   * @see TypedForm#setValue
   * @example
   * // sets the value of the collection
   * const valueMap = { 'contact-form': { email: '...', telefon: '...' } };
   * collection.setValue(valueMap);
   */
  setValue<T extends keyof CollectionType>(
    valueMap: { [formName in T]: CollectionType[formName] }
  ): TypedFormCollection<CollectionValue, CollectionType>;
  /**
   * Sets the value of a control within the collection.
   * This is done analogously to the Angular implmenentation.
   * @see TypedForm#setValue
   * @example
   * // sets the value of a control within the collection
   * const exactValue = '...';
   * collection.setValue(exactValue, 'email');
   */
  setValue<T extends keyof CollectionType>(value: any, path: string): TypedFormCollection<CollectionValue, CollectionType>;
  setValue<T extends keyof CollectionType>(
    value: { [formName in T]: CollectionType[formName] },
    path?: string
  ): TypedFormCollection<CollectionValue, CollectionType> {
    if (path) {
      const control = this.getOrThrowError(path);
      control.setValue(value);
    } else {
      Object.entries(value).forEach(([formName, val]) =>
        this.getForm(formName as any)?.setValue(val));
    }

    return this;
  }

  /**
   * Patches the value of the collection.
   * NOTE: This is done by patching the value into all forms.
   * @see TypedForm#patchValue
   * @example
   * // patches the value of the collection
   * const valueLike = { email: '...', foo: -3 }
   * collection.patchValue(valueLike);
   */
  patchValue(value: any): TypedFormCollection<CollectionValue, CollectionType>;
  /**
   * Patches the value of a control within the collection.
   * This is done analogously to the Angular implmenentation.
   * @see TypedForm#patchValue
   * @example
   * // patches the value of a control within the collection
   * const valueLike = '...';
   * collection.patchValue(valueLike, 'email');
   */
  patchValue(value: any, path: string): TypedFormCollection<CollectionValue, CollectionType>;
  patchValue(value: any, path?: string): TypedFormCollection<CollectionValue, CollectionType> {
    if (!path) {
      this.formNames
        .map(formName => this.getForm(formName))
        .forEach(form => form?.patchValue(value));
    } else {
      const form = this.getFormThatHasControl(path);
      if (!form) {
        throw Error('No form matching path!');
      }

      form.patchValue(value);
    }

    return this;
  }

  /**
   * Resets the collection.
   * If `toInitialValue` is set, the collection is reset to it's initial value.
   * @see TypedForm#reset
   * @example
   * // resets the collection
   * form.reset();
   *
   * // resets the collection to it's initial value
   * form.reset('toInitialValue');
   */
  reset(toInitialValue?: 'toInitialValue'): TypedFormCollection<CollectionValue, CollectionType> {
    this.formNames
      .map(formName => this.getForm(formName))
      .forEach(form => form?.reset(toInitialValue));

    return this;
  }

  /**
   * Destroys the collection.
   * @see TypedForm#destroy
   * @example
   * // destroys the collection
   * form.destroy();
   * // destroys the collection, together with it's child forms
   * form.destroy('butPreserveForm');
   */
  destroy(preserveForms: 'butPreserveForms' = 'butPreserveForms'): void {
    const destroySubscriptionWasNotUnsubscribed =
      this.autoDestroySubscription &&
      !this.autoDestroySubscription.closed;

    if (destroySubscriptionWasNotUnsubscribed) {
      this.autoDestroySubscription?.unsubscribe();
    }

    if (preserveForms) {
      this.formNames
        .filter(formName => this.hasForm(formName as any))
        .map(formName => this.getForm(formName))
        .forEach(form => { form?.destroy(); });
    }

    this._destroy$.next(this.name);
    this._destroy$.complete();
  }

  /**
   * Automatically destroys the collection whenever the provided observable emits.
   * NOTE: A manual call to {@link destroy} before the observable emits still causes the collection to destroy.
   * @see TypedForm#destroy
   * @example
   * // let the collection self-destroy
   * collection.destroyWhen(this.isDestroyed$);
   * // let the collection self-destroy, together with it's child forms
   * collection.destroyWhen(this.isDestroyed$, 'butPreserveForm');
   */
  destroyWhen(
    selfDestruct$: Observable<any>,
    preserveForms: 'butPreserveForms' = 'butPreserveForms'
  ): TypedFormCollection<CollectionValue, CollectionType> {
    this.autoDestroySubscription = selfDestruct$.subscribe({ next: () => { this.destroy(preserveForms); } });

    return this;
  }

  /**
   * Enables the collection.
   * @see TypedForm#enable
   * @example
   * // enables the collection
   * collection.enable();
   */
  enable(): TypedFormCollection<CollectionValue, CollectionType>;
  /**
   * Enables a control within the collection.
   * @see TypedForm#enable
   * @example
   * // enables a control
   * collection.enable('email');
   */
  enable(path: string): TypedFormCollection<CollectionValue, CollectionType>;
  enable(path?: string): TypedFormCollection<CollectionValue, CollectionType> {
    if (path) {
      const control = this.getOrThrowError(path);
      control?.enable();
    } else {
      this.formNames
        .map(formName => this.getForm(formName))
        .forEach(form => form?.enable());
    }

    return this;
  }

  /**
   * Disables the collection.
   * @see AbstractControl#disable
   * @example
   * // disables the collection
   * collection.disable();
   */
  disable(): TypedFormCollection<CollectionValue, CollectionType>;
  /**
   * Disables a control within the collection.
   * @see TypedForm#disable
   * @example
   * // disables a control
   * collection.disable('email');
   */
  disable(path: string): TypedFormCollection<CollectionValue, CollectionType>;
  disable(path?: string): TypedFormCollection<CollectionValue, CollectionType> {
    if (path) {
      const control = this.getOrThrowError(path);
      control?.disable();
    } else {
      this.formNames
        .map(formName => this.getForm(formName))
        .forEach(form => form?.disable());
    }

    return this;
  }

  /**
   * Sets the validators for a control within the collection.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the validators to take effect, {@link updateValueAndValidity} needs to be called.
   * @see TypedForm#setValidators
   * @example
   * // sets a controls validators and triggers validation
   * collection.setValidators('email', Validators.required);
   * collection.updateValueAndValidity('email');
   *
   * // multiple validators can be set at once
   * collection.setValidators('email', [Validators.required, Validators.maxLength(5)])
   */
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>, path: string): TypedFormCollection<CollectionValue, CollectionType> {
    const control = this.getOrThrowError(path);
    control.setValidators(newValidator);

    return this;
  }

  /**
   * Clears all validators for a control within the collection.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the clear to take effect, {@link updateValueAndValidity} needs to be called.
   * @see TypedForm#setValidators
   * @example
   * // clears a controls validators and triggers validation
   * collection.setValidators('email', Validators.required);
   * collection.updateValueAndValidity('email');
   */
  clearValidators(path: string): TypedFormCollection<CollectionValue, CollectionType> {
    const control = this.getOrThrowError(path);
    control.clearValidators();

    return this;
  }

}
