import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AppTheme } from '@core/shared/domain';

import { APP_THEME_LOCAL_STORAGE_KEY } from './app-theme-key';
import { AbstractThemeChangesTrackingService } from './development';

@Injectable({
  providedIn: 'root',
})
export class ThemeHandlerService {
  private _currentTheme$: BehaviorSubject<AppTheme> = new BehaviorSubject<AppTheme>(AppTheme.DEFAULT);

  currentTheme$: Observable<AppTheme> = this._currentTheme$.asObservable();

  private get currentTheme(): AppTheme {
    return this._currentTheme$.value;
  }

  private readonly THEME_COLORS_FILE_BASE_NAME = 'theme-colors';

  private readonly THEME_COLORS_LINK_ID = 'theme-colors-stylesheet';

  private readonly FAVICON_ID = 'favicon';

  private readonly FAVICON_FILENAME = 'favicon.ico';

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() private readonly themeChangesTrackingService: AbstractThemeChangesTrackingService | null,
  ) {
    if (this.themeChangesTrackingService) {
      this.themeChangesTrackingService.themeChanges$.pipe(tap((theme: AppTheme) => this.setTheme(theme))).subscribe();
    }
  }

  setTheme(theme: AppTheme): Observable<unknown> {
    this._currentTheme$.next(theme);

    this.setupFavicon();
    this.saveThemeInLocalStorage();

    return this.linkThemeColorsStylesheet();
  }

  private setupFavicon(): void {
    const faviconEl: HTMLElement | null = this.document.getElementById(this.FAVICON_ID);
    faviconEl?.setAttribute('href', this.getFaviconPath());
  }

  private getFaviconPath(): string {
    return `assets/${this.currentTheme}/${this.FAVICON_FILENAME}`;
  }

  private saveThemeInLocalStorage(): void {
    // Mostly needed to also be able to access this on error page
    localStorage.setItem(APP_THEME_LOCAL_STORAGE_KEY, this.currentTheme);
  }

  private linkThemeColorsStylesheet(): Observable<unknown> {
    let themeColorsLink: HTMLElement | null = this.document.getElementById(this.THEME_COLORS_LINK_ID);

    if (!themeColorsLink) {
      themeColorsLink = this.createThemeColorsLinkElement();
      this.document.head.appendChild(themeColorsLink);
    }

    themeColorsLink.setAttribute('href', this.getCurrentThemeColorsPath());

    return fromEvent(themeColorsLink, 'load').pipe(first());
  }

  private createThemeColorsLinkElement(): HTMLLinkElement {
    const themeColorsLink = this.document.createElement('link');

    themeColorsLink.setAttribute('id', this.THEME_COLORS_LINK_ID);
    themeColorsLink.setAttribute('rel', 'stylesheet');
    themeColorsLink.setAttribute('href', this.getCurrentThemeColorsPath());

    return themeColorsLink;
  }

  private getCurrentThemeColorsPath(): string {
    return `${this.THEME_COLORS_FILE_BASE_NAME}.${this.currentTheme}.css`;
  }
}
