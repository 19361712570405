/* eslint-disable no-useless-escape */

/**
 * RegExp for url without query params
 */
export const RAW_URL_PATTERN =
  /^https?:\/\/(www\.)?([a-zA-Z0-9_\-]{1,256}\.)+[a-z0-9]{1,6}(:[0-9]{1,5})?(\/[a-zA-Z0-9_\-]+)*?\/?$/;

/**
 * RegExp for url that can include query params
 */
export const URL_PATTERN =
  /^https?:\/\/(www\.)?([a-zA-Z0-9_\-]{1,256}\.)+[a-z0-9]{1,6}(:[0-9]{1,5})?(\/[a-zA-Z0-9_\-#]+)*?\/?((#|\?)[a-zA-Z0-9\-@:%_+.~#?&\/=]*)?$/;
