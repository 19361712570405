import { SelectionModel } from '@angular/cdk/collections';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TypedForm } from '../typed-wrappers/typed-form';
import { TypedFormCollection } from '../typed-wrappers/typed-form-collection';

export class FormsMap<FormsState> {

  private readonly forms: Map<keyof FormsState, TypedForm> = new Map();
  private readonly formCollections: Map<keyof FormsState, TypedFormCollection> = new Map();

  private readonly selection = new SelectionModel<keyof FormsState>(true);

  forms$: Observable<Array<TypedForm>>;
  formCreated$: Observable<keyof FormsState>;
  formDestroyed$: Observable<keyof FormsState>;

  constructor() {
    this.forms$ = this.selection.changed.pipe(
      map(() => this.selection.selected.map(name => this.forms.get(name) as TypedForm)),
      shareReplay(1)
    );
    this.formCreated$ = this.selection.changed.pipe(
      map(change => change.added[0])
    );
    this.formDestroyed$ = this.selection.changed.pipe(
      map(change => change.removed[0])
    );
  }

  hasForm(name: keyof FormsState): boolean {
    return this.selection.selected.includes(name);
  }

  getForm<T>(name: keyof FormsState): TypedForm<T> | null {
    return this.hasForm(name) ?
      this.forms.get(name) ?? null :
      null;
  }

  unregisterForm(name: keyof FormsState): void {
    this.forms.delete(name as any);
    this.selection.deselect(name);
  }

  registerForm(form: TypedForm): void {
    this.forms.set(form.name as any, form);
    this.selection.select(form.name as any);
  }

  hasFormCollection(name: keyof FormsState): boolean {
    return this.formCollections.has(name);
  }

  getFormCollection(name: keyof FormsState): TypedFormCollection | null {
    return this.formCollections.get(name) ?? null;
  }

  registerFormCollection(collection: TypedFormCollection): void {
    this.formCollections.set(collection.name as any, collection);
  }

  unregisterFormCollection(name: keyof FormsState): void {
    this.formCollections.delete(name);
  }

  clear(): void {
    this.forms.forEach(form => { form.destroy(); });
    this.forms.clear();

    this.formCollections.forEach(collection => { collection.destroy(); });
    this.formCollections.clear();
  }

}
