import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandlerService extends ErrorHandler {
  private errorServices: Array<ErrorHandler> = [];

  constructor() {
    super();
  }

  public override handleError(error: unknown) {
    // Angular default behavior - processed error is logged in the console
    super.handleError(error);

    this.errorServices.forEach((errorService) =>
      errorService.handleError(error)
    );
  }

  public addErrorHandler(errorService: ErrorHandler): void {
    if (errorService?.handleError && typeof errorService.handleError === 'function') {
      this.errorServices.push(errorService);
    }
  }

  public removeErrorHandler(errorService: ErrorHandler): void {
    if (errorService) {
      const idx = this.errorServices.indexOf(errorService);
      if (idx !== -1) {
        this.errorServices.splice(idx, 1);
      }
    }
  }
}
