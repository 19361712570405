import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatePipeIntl, DatePipeIntlGerman } from './date-pipe-intl';
import { HumanizeRelativeDatePipe } from './humanize-relative-date.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [HumanizeRelativeDatePipe],
  exports: [HumanizeRelativeDatePipe],
  providers: [{ provide: DatePipeIntl, useClass: DatePipeIntlGerman }],
})
export class DatePipesModule {}
