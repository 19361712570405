import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'stripHTML' })
export class StripHTMLPipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) {}

  transform(value: string): string;
  transform(value?: string | null): string | undefined;

  transform(value?: string | null): string | undefined {
    if (value === undefined || value === null) {
      return undefined;
    }

    const sanitizedValue: string | null = this.domSanitizer.sanitize(SecurityContext.HTML, value);

    if (!sanitizedValue) {
      return undefined;
    }

    const tmpElement: HTMLDivElement = document.createElement('div');
    tmpElement.innerHTML = sanitizedValue ?? '';

    return tmpElement.textContent || tmpElement.innerText;
  }
}
