import { Directive, InputSignal, TemplateRef, input } from '@angular/core';

import { TemplateContext } from '../types';

@Directive({
  selector: 'ng-template[mpTypedTemplate]',
  exportAs: 'mpTypedTemplate',
  standalone: true,
})
export class TypedTemplateDirective<T extends TemplateContext> {
  /**
   * Input for providing template context type.
   * It is used to infer the type of the template context object - type of the $implicit property and extra properties.
   */
  readonly contextType: InputSignal<T | undefined> = input<T>();

  constructor(public template: TemplateRef<T>) {}

  static ngTemplateContextGuard<TContext extends TemplateContext>(
    dir: TypedTemplateDirective<TContext>,
    ctx: unknown,
  ): ctx is TContext {
    return true;
  }
}
