import { AbstractControl, UntypedFormArray, ValidatorFn } from '@angular/forms';

import { distinctUntilChanged, map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FillMethod, FormFiller } from '../utils/form-filler';
import { findControl, findValue } from '../utils/builders';
import { ArrayEntryBuilderManager } from '../utils/array-entry-builder-manager';
import { ControlSnapshot } from './control-snapshot';
import { FormEvaluator } from '../utils/form-evaluator';

type ParentFormDependency<Type = any> = {
  arrayEntryBuilderManager: ArrayEntryBuilderManager;
  formFiller: FormFiller;
  formEvaluator: FormEvaluator;
  initialValue: Type;

  get<R extends AbstractControl>(path: string): R;
  controlChanges$<T = Type>(path: string): Observable<ControlSnapshot<T>>;
};

export class TypedFormArray<Type = any> {

  private readonly formArray: UntypedFormArray;

  private readonly _controlChanges$: Observable<ControlSnapshot<Type>>;

  get length(): number {
    return this.formArray.controls.length;
  }

  get controls(): Array<AbstractControl> {
    return this.formArray.controls;
  }

  private get initialValue(): Array<Type> {
    return findValue(this.parentForm.initialValue, this.path);
  }

  private get formFiller(): FormFiller {
    return this.parentForm.formFiller;
  }

  private get formEvaluator(): FormEvaluator {
    return this.parentForm.formEvaluator;
  }

  private get arrayEntryBuilderManager(): ArrayEntryBuilderManager {
    return this.parentForm.arrayEntryBuilderManager;
  }

  constructor(
    private readonly path: string,
    private readonly parentForm: ParentFormDependency
  ) {
    this.validatePathAndThrowError(path, parentForm);

    this.formArray = parentForm.get(path);
    this._controlChanges$ = parentForm.controlChanges$(path);
  }

  private validatePathAndThrowError(path: string, parentForm: ParentFormDependency<Type>): void {
    const pathExists = !!parentForm.get(path);
    const controlWithPathIsFormArray = parentForm.get(path) instanceof UntypedFormArray;

    if (!pathExists) {
      throw Error(`There is no control registered unter the path "${ path }"!`);
    }

    if (!controlWithPathIsFormArray) {
      throw Error(`The control registered with path "${ path }" is not a form array!`);
    }
  }

  /**
   * Emits the current validity of the array.
   * @example
   * // emits the validity of the array
   * formArray.validityChanges$().subscribe(changed => { ... });
   */
  validityChanges$(): Observable<boolean>;
  /**
   * Emits the current validity of a control within the array.
   * @example
   * // emits the validity of the array
   * formArray.validityChanges$(2).subscribe(changed => { ... });
   */
  validityChanges$(index: number): Observable<boolean>;
  validityChanges$(index?: number): Observable<boolean> {
    return this
      .controlChanges$(index as any)
      .pipe(pluck('valid'));
  }

  /**
   * Emits the value of the array.
   * @see FormArray#valueChanges
   * @example
   * // emits the value of the array
   * formArray.valueChanges$().subscribe(changed => { ... });
   */
  valueChanges$(): Observable<Array<Type>>;
  /**
   * Emits the value of a control within the array.
   * @see FormArray#valueChanges
   * @example
   * // emits the value of a control within the form
   * formArray.valueChanges$(2).subscribe(changed => { ... });
   */
  valueChanges$<R = any>(index: number): Observable<R>;
  valueChanges$<R = Array<Type>>(index?: number): Observable<R> {
    return this
      .controlChanges$(index as any)
      .pipe(pluck('value')) as any;
  }

  /**
   * Emits the `errors`-object of a control within the array.
   * @see FormArray#errors
   * @example
   * // emits the errors of the array
   * formArray.errorsChanges$('email').subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(): Observable<Errors>;
  /**
   * Emits the `errors`-object of a control within the array.
   * @see FormArray#errors
   * @example
   * // emits the errors of a control within the array
   * formArray.errorsChanges$('email').subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(index: number): Observable<Errors>;
  errorsChanges$<Errors = any>(index?: number): Observable<Errors> {
    return this
      .controlChanges$(index as any)
      .pipe(pluck('errors')) as Observable<Errors>;
  }

  /**
   * Emits the snapshot of the array.
   * NOTE: This observable is fired whenever ANY kind of change occurs (value or state).
   * @see FormArray#valueChanges
   * @see FormArray#statusChanges
   * @see ControlSnapshot
   * @example
   * // subscribes to any state changes
   * form.controlChanges$().subscribe(snapshot => { ... });
   */
  controlChanges$(): Observable<ControlSnapshot<Type>>;
  /**
   * Emits the snapshot of a control within the array.
   * NOTE: This observable is fired whenever ANY kind of change occurs (value or state).
   * @see FormArray#valueChanges
   * @see FormArray#statusChanges
   * @see ControlSnapshot
   * @example
   * // subscribes to any state changes
   * formArray.controlChanges$(2).subscribe(snapshot => { ... });
   */
  controlChanges$(index: number): Observable<ControlSnapshot<Type>>;
  controlChanges$<T = Type>(index?: number): Observable<ControlSnapshot<T>> {
    return index === undefined ?
      this._controlChanges$ :
      this._controlChanges$.pipe(
        map(snapshot => findControl(snapshot, String(index))),
        distinctUntilChanged()
      ) as any;
  }

  /**
   * Returns the value of the array.
   * @see FormArray#value
   * @example
   * // value of the array
   * formArray.value();
   */
  value<T = Type>(): Array<T>;
  /**
   * Returns the value of a control within the form.
   * If no control exists under the given path, `null` is returned.
   * @see FormArray#value
   * @example
   * // value of a control within the array
   * formArray.value(2);
   */
  value<R = Type>(index: number): R;
  value<T = Type>(index?: number): Array<T> {
    const arrayValue: Array<T> = this.formEvaluator.value(this.formArray);
    return (index === undefined ? arrayValue : arrayValue[index]) as any;
  }

  /**
   * Removes an entry from the array.
   * @example
   * // remove entry
   * formArray.removeAt(2);
   */
  removeAt(index: number): TypedFormArray<Type> {
    this.formArray.removeAt(index);

    return this;
  }

  private buildnewEntryIfBuilderRegistered(): AbstractControl | null {
    const entryBuilder = this.arrayEntryBuilderManager.getBuilder(this.path);

    return entryBuilder ? entryBuilder() : null;
  }

  /**
   * Pushes a new control onto the array.
   * If no control to append is provided, the registered entryBuilder is used.
   * @see setArrayEntryBuilder
   * @example
   * // push new value using a provided control
   * formArray.push('adressen', new FormControl(null));
   *
   * // push new value with no control provided
   * formArray.setArrayEntryBuilder('adressen', () => new FormControl(null))
   *     .push('adressen');
   */
  push(control?: AbstractControl): TypedFormArray<Type> {
    const controlToPush = control ?? this.buildnewEntryIfBuilderRegistered();
    if (!controlToPush) {
      throw Error('No control or entry-builder provided!');
    }

    this.formArray.push(controlToPush);

    return this;
  }

  /**
   * Pushes a new control - filled with the provided value - onto a FormArray within the form.
   * If no control to append is provided, the registered entryBuilder is used.
   * @see setArrayEntryBuilder
   * @example
   * // push new value using a provided control
   * formArray.pushValue('90 Bedford St, New York, NY 10014', new FormControl(null));
   *
   * // push new value with no control provided
   * formArray.setEntryBuilder(() => new FormControl(null))
   *     .pushValue('90 Bedford St, New York, NY 10014');
   *
   * // a preferred method of filling can also be passed
   * formArray.pushValue('90 Bedford St, New York, NY 10014', new FormControl(null), 'set');
   * // since default is patch, these can be used interchageably
   * formArray.pushValue('90 Bedford St, New York, NY 10014', new FormControl(null));
   * formArray.pushValue('90 Bedford St, New York, NY 10014', new FormControl(null), 'patch');
   */
  pushValue(value: any, control?: AbstractControl, method: FillMethod = 'patch'): TypedFormArray<Type> {
    const controlToPush = control ?? this.buildnewEntryIfBuilderRegistered();
    if (!controlToPush) {
      throw Error('No control or entry-builder provided!');
    }

    if (value !== undefined) {
      this.formFiller.fillValue(controlToPush, value, method, this.path);
    }

    this.formArray.push(controlToPush);

    return this;
  }

  /**
   * Inserts a new control into the array.
   * If no control to insert is provided, the registered entryBuilder is used.
   * @see setArrayEntryBuilder
   * @example
   * // push new value using a provided control
   * formArray.insert(2, new FormControl(null));
   *
   * // push new value with no control provided
   * formArray.setArrayEntryBuilder(2, () => new FormControl(null))
   *     .insert(2);
   */
  insert(index: number, control?: AbstractControl): TypedFormArray<Type> {
    const controlToPush = control ?? this.buildnewEntryIfBuilderRegistered();
    if (!controlToPush) {
      throw Error('No control or entry-builder provided!');
    }

    this.formArray.insert(index, controlToPush);

    return this;
  }

  /**
   * Inserts a new control into the array.
   * If no control to insert is provided, the registered entryBuilder is used.
   * @see setArrayEntryBuilder
   * @example
   * // push new value using a provided control
   * formArray.insert(2, new FormControl(null));
   *
   * // push new value with no control provided
   * formArray.setArrayEntryBuilder(2, () => new FormControl(null))
   *     .insert(2);
   */
  insertValue(index: number, value: any, control?: AbstractControl, method: FillMethod = 'patch'): TypedFormArray<Type> {
    const controlToInsert = control ?? this.buildnewEntryIfBuilderRegistered();
    if (!controlToInsert) {
      throw Error('No control or entry-builder provided!');
    }

    if (value !== undefined) {
      this.formFiller.fillValue(controlToInsert, value, method, this.path);
    }

    this.formArray.insert(index, controlToInsert);

    return this;
  }

  /**
   * Gets a specific control from the array.
   * Returns the arrays underlying FormArray if no further path is specified.
   * If no form was registered under the given name, `undefined` is returned.
   * If no control was registered under the given path, `null` is returned.
   * @see AbstractControl#get
   * @example
   * // gets a control within the array
   * formArray.get(2);
   * formArray.get(1, 'email');
   */
  get(): UntypedFormArray;
  get<R extends AbstractControl = AbstractControl>(index: number, path?: string): R | null;
  get<R extends AbstractControl = AbstractControl>(index?: number, path?: string): R | null {
    if (!index) {
      return this.formArray as any;
    }

    return (path ?
      this.formArray.controls[index]?.get(path) :
      this.formArray.controls[index]) as R;
  }

  private getOrThrowError<T extends AbstractControl = AbstractControl>(index?: number, path?: string): T {
    const control = this.get(index as any, path);

    if (!control) {
      throw Error(`There is no control registered unter the path "${ path }"!`);
    }

    return control as T;
  }

  /**
   * Checks if the array has a control registered under a given path.
   * Returns `true` if a control is registered under that path for that array
   * and `false` otherwise.
   * @example
   * // checks if a control exists within the array
   * formArray.has(2);
   * formArray.has(1, 'email');
   */
  has(index: number, path?: string): boolean {
    return !!this.get(index, path);
  }

  /**
   * Registers an "entry-builder"-function with a FormArray within the form.
   * NOTE: This entry-builder is used by the form filler to automatically append
   *       new entries to FormArrays when filling the form.
   * @see FormFiller#fillValue
   * @see ArrayEntryBuilderManager
   * @example
   * // register a new array entry builder
   * const entryBuilder = () => new FormControl(null);
   * formArray.setEntryBuilder(entryBuilder);
   */
  setEntryBuilder(builder: () => AbstractControl): TypedFormArray<Type> {
    this.arrayEntryBuilderManager.registerBuilder(this.path, builder);

    return this;
  }

  /**
   * Checks if an "entry-builder"-function was registered for the array.
   * Returns `true` if a builder was registered
   * and `false` otherwise.
   * @see FormFiller#fillValue
   * @see ArrayEntryBuilderManager
   * @example
   * // register a new array entry builder
   * const entryBuilder = () => new FormControl(null);
   * formArray.setEntryBuilder(entryBuilder);
   */
  hasEntryBuilder(): boolean {
    return this.arrayEntryBuilderManager.hasBuilder(this.path);
  }

  /**
   * Sets the value of the array.
   * This is done analogously to the Angular implementation.
   * @see FormArray#setValue
   * @example
   * // sets the value of the array
   * const exactValue = { email: '...', telefon: '...' };
   * formArray.setValue(exactValue);
   */
  setValue(value: any): TypedFormArray<Type> {
    this.formFiller.setArrayValue(this.formArray, value, this.path);

    return this;
  }

  /**
   * Patches the value of the arrray.
   * This is done analogously to the Angular implementation.
   * @see AbstractControl#patchValue
   * @example
   * // patches the value of the array
   * const valueLike = { email: '...', foo: -3 }
   * formArray.patchValue(valueLike);
   */
  patchValue(value: any): TypedFormArray<Type> {
    this.formFiller.patchArrayValue(this.formArray, value, this.path);

    return this;
  }

  /**
   * Resets the array.
   * If `toInitialValue` is set, the array is reset to it's initial value.
   * @see FormArray#reset
   * @example
   * // reset the form
   * formArray.reset();
   *
   * // reset the form to it's initial value
   * formArray.reset('toInitialValue');
   */
  reset(toInitialValue?: 'toInitialValue'): TypedFormArray<Type> {
    this.formArray.clear();

    if (toInitialValue) {
      this.patchValue(this.initialValue);
    }

    return this;
  }

  /**
   * Enables the array.
   * @see FormArray#enable
   * @example
   * // enables the array
   * array.enable();
   */
  enable(): TypedFormArray<Type>;
  /**
   * Enables a control within the array.
   * @see FormArray#enable
   * @example
   * // enables a control
   * array.enable('email');
   */
  enable(index: number): TypedFormArray<Type>;
  enable(index?: number): TypedFormArray<Type> {
    const control = this.getOrThrowError(index);
    control.enable();

    return this;
  }

  /**
   * Disables the array.
   * @see FormArray#disable
   * @example
   * // disables the form
   * array.disable();
   */
  disable(): TypedFormArray<Type>;
  /**
   * Disables a control within the array.
   * @see FormArray#disable
   * @example
   * // disables a control
   * array.disable('email');
   */
  disable(index: number): TypedFormArray<Type>;
  disable(index?: number): TypedFormArray<Type> {
    const control = this.getOrThrowError(index);
    control.disable();

    return this;
  }

  /**
   * Sets the validators for the array.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the validators to take effect, {@link updateValueAndValidity} needs to be called.
   * @see FormArray#setValidators
   * @example
   * // set the arrays validators and triggers validation
   * array.setValidators(Validators.required);
   * array.updateValueAndValidity();
   *
   * // multiple validators can be set at once
   * array.setValidators([Validators.required, Validators.maxLength(5)])
   */
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>): TypedFormArray<Type>;
  /**
   * Sets the validators for a control within the array.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the validators to take effect, {@link updateValueAndValidity} needs to be called.
   * @see FormArray#setValidators
   * @example
   * // sets a controls validators and triggers validation
   * array.setValidators(2, Validators.required);
   * array.updateValueAndValidity(2);
   *
   * // multiple validators can be set at once
   * array.setValidators(2, [Validators.required, Validators.maxLength(5)])
   */
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>, index: number): TypedFormArray<Type>;
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>, index?: number): TypedFormArray<Type> {
    const control = this.getOrThrowError(index);
    control.setValidators(newValidator);

    return this;
  }

  /**
   * Clears all validators for the array.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the clear to take effect, {@link updateValueAndValidity} needs to be called.
   * @see FormArray#setValidators
   * @example
   * // clears the forms validators and triggers validation
   * array.setValidators(Validators.required);
   * array.updateValueAndValidity();
   */
  clearValidators(): TypedFormArray<Type>;
  /**
   * Clears all validators for a control within the array.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the clear to take effect, {@link updateValueAndValidity} needs to be called.
   * @see FormArray#setValidators
   * @example
   * // clears a controls validators and triggers validation
   * array.setValidators('email', Validators.required);
   * array.updateValueAndValidity('email');
   */
  clearValidators(index: number): TypedFormArray<Type>;
  clearValidators(index?: number): TypedFormArray<Type> {
    const control = this.getOrThrowError(index);
    control.clearValidators();

    return this;
  }

}
