import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AppTheme } from '@core/shared/domain';

import { APP_THEME_LOCAL_STORAGE_KEY } from '../app-theme-key';

import { AbstractThemeChangesTrackingService } from './abstract-theme-changes-tracking.service';

@Injectable()
export class ThemeChangesTrackingDevService extends AbstractThemeChangesTrackingService {
  private readonly _themeChanges$: Subject<AppTheme> = new Subject<AppTheme>();

  readonly themeChanges$: Observable<AppTheme> = this._themeChanges$.asObservable();

  constructor(private readonly ngZone: NgZone) {
    super();

    this.trackThemeChanges();
  }

  private trackThemeChanges(): void {
    this.ngZone.runOutsideAngular(() => {
      // Track theme changes done by hand in local storage
      window.addEventListener('storage', (event) => {
        if (event.key !== APP_THEME_LOCAL_STORAGE_KEY) {
          return;
        }

        this.ngZone.run(() => this._themeChanges$.next(event.newValue as AppTheme));
      });
    });
  }
}
