import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ControlSnapshot, EditableControlSnapshot } from '../typed-wrappers/control-snapshot';

type ControlFactory = (value?: unknown) => AbstractControl;

export function toStore<FormsState>(name: keyof FormsState, control: AbstractControl): ControlSnapshot {

  function controlToStore(control: UntypedFormControl): ControlSnapshot {
    return buildValue(control);
  }
  
  function groupToStore(name: keyof FormsState, group: UntypedFormGroup): ControlSnapshot {
    const value = buildValue(group);
  
    for (const key of Object.keys(group.controls)) {
      const current = group.controls[key];
      const currentIsFormControl = current instanceof UntypedFormControl;
  
      if (value.controls) {
        (value.controls[key] as EditableControlSnapshot) = currentIsFormControl ?
          buildValue(current) :
          toStore(name, current);
      }
    }

    return value;
  }
  
  function arrayToStore(name: keyof FormsState, array: UntypedFormArray): ControlSnapshot {
    const value = buildValue(array);
  
    for (let i = 0; i < array.controls.length; i++) {
      const current = array.controls[i];
      const currentIsFormControl = current instanceof UntypedFormControl;

      if (value.controls) {
        (value.controls[i] as EditableControlSnapshot) = currentIsFormControl ?
          buildValue(current) :
          toStore(name, current);
      }
    }
  
    return value;
  }

  if (control instanceof UntypedFormControl) {
    return controlToStore(control);
  }
  
  if (control instanceof UntypedFormGroup) {
    return groupToStore(name, control);
  }

  if (control instanceof UntypedFormArray) {
    return arrayToStore(name, control);
  }

  throw Error('AbstractControl is neither Control, nor Group, nor Array!');
}

export function handleFormArray(
  formValue: Record<string, any> | Array<any>,
  control: AbstractControl,
  arrControlFactory: Record<string, ControlFactory> | ControlFactory
): void {
  if (control instanceof UntypedFormArray) {
    clearFormArray(control);

    if (!arrControlFactory) {
      throw new Error('Please provide arrControlFactory');
    }

    formValue.forEach((value: any, index: number) => {
      control.insert(index, (arrControlFactory as ControlFactory)(value));
    });

  } else {
    Object.keys(formValue).forEach(controlName => {
      /*
       * These typings ( "(formValue as any)[controlName]" ) come from JS allowing arrays and
       * objects to be iterated over using Object.keys(),
       * while TS dissallows this due to inconsistent typings.
       * So while this may work in practice:
       * 
       * const array = ['A', 'B', 'C'];
       * array[Object.keys(array)[0]] // 'A'
       * 
       * TS disallows this.
       * The casting is a hacky fix to allow the code to compile,
       * while still bearing no technical issues besides typings.
       */ 
      const value = (formValue as any)[controlName];

      if (Array.isArray(value) && control.get(controlName) instanceof UntypedFormArray) {

        if (!arrControlFactory || (arrControlFactory && !(controlName in arrControlFactory))) {
          throw new Error(`Please provide arrControlFactory for ${controlName}`);
        }

        const current = control.get(controlName) as UntypedFormArray;
        const fc = (arrControlFactory as any)[controlName];

        clearFormArray(current);
        value.forEach((val: any, index: number) => { current.insert(index, fc(val)); });
      }
    });
  }
}

export function findControl(control: ControlSnapshot, path: string): ControlSnapshot | null {
  if (!control.controls) {
    throw Error('Control has no nested controls to search through!');
  }

  const [first, ...rest] = path.split('.');
  if (rest.length === 0) {
    return control.controls[first];
  }

  try {
    return rest.reduce(
      (current: ControlSnapshot, name: string) => {
        if (current.controls) {
          if (Object.prototype.hasOwnProperty.call(current.controls, name)) {
            return current.controls[name];
          }
        }
        
        throw Error('Path is invalid!');
      },
      control.controls[first]
    );
  } catch (error) {
    return null;
  }
}

export function findValue(obj: any, path: string): any {
  const [first, ...rest] = path.split('.');
  if (rest.length === 0) {
    return obj[first];
  }

  return rest.reduce(
    (current: any, name: string) =>
      Object.prototype.hasOwnProperty.call(current, name) ? current[name] : null,
    obj[first]
  );
}

export function buildValue(control: Partial<AbstractControl>): ControlSnapshot {
  const value: Record<string, unknown> = {
    // value: clone(control.value), // Clone object to prevent issue with third party that would be affected by store freezing.
    value: control.value,
    rawValue: (control as any).getRawValue ? (control as any).getRawValue() : null,
    valid: control.valid,
    dirty: control.dirty,
    invalid: control.invalid,
    disabled: control.disabled,
    errors: control.errors,
    touched: control.touched,
    pristine: control.pristine,
    pending: control.pending
  };

  if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
    value['controls'] = control instanceof UntypedFormArray ? [] : {};
  }

  return value as ControlSnapshot;
}

export function clearFormArray(control: UntypedFormArray): void {
  while (control.length !== 0) {
    control.removeAt(0);
  }
}
