import { BehaviorSubject, Observable } from 'rxjs';
import { map, skip } from 'rxjs/operators';

export class ReactiveMap<TKey extends string | number | symbol, TValue> {

  private readonly _state$: BehaviorSubject<Record<TKey, TValue>>;
  readonly state$: Observable<Record<TKey, TValue>>;
  readonly stateChanges$: Observable<Record<TKey, TValue>>;

  get value(): Record<TKey, TValue> {
    return this._state$.getValue();
  }

  constructor (initialValue: Record<TKey, TValue>) {
    this._state$ = new BehaviorSubject(initialValue);
    this.state$ = this._state$.asObservable();
    this.stateChanges$ = this.state$.pipe(skip(1));
  }

  entries$(): Observable<Array<[TKey, TValue]>> {
    return this._state$.pipe(map(value => Object.entries(value) as Array<[TKey, TValue]>));
  }

  values$(): Observable<Array<TValue>> {
    return this._state$.pipe(map(value => Object.values(value)));
  }

  keys$(): Observable<Array<TKey>> {
    return this._state$.pipe(map(value => Object.keys(value) as Array<TKey>));
  }

  set(key: TKey, value: TValue): void {
    this._state$.next({ ...this.value, ...{ [key]: value } });
  }

  setMany(values: Record<TKey, TValue>): void {
    this._state$.next({ ...this.value, ...values });
  }

  get(key: TKey): TValue | undefined {
    return this._state$.getValue()[key];
  }

  unset(key: TKey): void {
    if (this.hasKey(key)) {
      delete this.value[key];
      this._state$.next({ ...this.value });
    }
  }

  hasKey(key: TKey): boolean {
    return this.value[key] !== undefined;
  }

  isEmpty(): boolean {
    return Object.keys(this.value).length === 0;
  }

  clear(): void {
    if (!this.isEmpty()) {
      this._state$.next({} as Record<TKey, TValue>);
    }
  }

}
