import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ThemeHandlerService } from '../../theme-handler';

@Pipe({
  name: 'mpThemeAssetsPath',
  standalone: true,
})
export class ThemeAssetsPathPipe implements PipeTransform {
  constructor(private readonly themeHandlerFacade: ThemeHandlerService) {}

  transform(assetPath: string): Observable<string> {
    return this.themeHandlerFacade.currentTheme$.pipe(map((theme) => `assets/${theme}/${assetPath}`));
  }
}
