export interface CountUpOptions {
  startVal?: number; // number to start at (0)
  decimalPlaces?: number; // number of decimal places (0)
  duration?: number; // animation duration in seconds (2)
  useGrouping?: boolean; // example: 1,000 vs 1000 (true)
  useEasing?: boolean; // ease animation (true)
  smartEasingThreshold?: number; // smooth easing for large numbers above this if useEasing (999)
  smartEasingAmount?: number; // amount to be eased for numbers above threshold (333)
  separator?: string; // grouping separator (',')
  decimal?: string; // decimal ('.')
  easingFn?: (t: number, b: number, c: number, d: number) => number; // easingFn: easing function for animation (easeOutExpo)
  formattingFn?: (n: number) => string; // this function formats result
  prefix?: string; // text prepended to result
  suffix?: string; // text appended to result
  numerals?: Array<string>; // numeral glyph substitution
}

export namespace CountUpOptions {

  export const Numeric = {
    useEasing: true,
    duration: 2, // in seconds
  
    separator: '.',
    decimal: ','
  };
  
  export const Currency = {
    ...Numeric,
    suffix: ' €'
  };

}
