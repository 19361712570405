import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, throttleTime } from 'rxjs';

@Injectable()
export class RestoreScrollingTriggerService implements OnDestroy {
  private readonly TRIGGER_THROTTLE_TIME = 50;

  private readonly _trigger$: ReplaySubject<void> = new ReplaySubject<void>(1);
  readonly scrollingTrigger$: Observable<void> = this._trigger$
    .asObservable()
    .pipe(throttleTime(this.TRIGGER_THROTTLE_TIME));

  triggerScroll(): void {
    this._trigger$.next();
  }

  ngOnDestroy(): void {
    this._trigger$.complete();
  }
}
