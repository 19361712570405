import { Primitive } from 'utility-types';

export namespace LocalStorage {

  class AccessError extends Error {
    constructor (message: string) {
      super (message);
    }
  }
  
  const STRINGIFICATION_FAILED_FOR = (key: string, value: string) => 
    `Value for "${key}" could not be written.
    This might be because the given object ${value} contains undefined, cyclic properties or uses forbidden characters.`;
  
  const PARSING_FAILED_FOR = (key: string, jsonString: string | null) => 
    `Value for "${key}" could not be read.
    This is because the JSON-string "${jsonString}" read from localstorage is invalid.`;
  
  const NO_KEY_PROVIDED = 'No key provided! Key is null or undefined.';
  const NO_VALUE_PROVIDED = 'No value provided! Value is null or undefined.';
  
  const UNKNOWN = 'Unknown error occured!';
  
  const LOCALSTORAGE_UNAVAILABLE = 
    `Value could not be read from localstorage.
    This is because localstorage is disabled for this site.`;
  
  export class StringificationError extends AccessError {
    constructor (key: string, value: Primitive) {
      super(STRINGIFICATION_FAILED_FOR(key, value as string));
    }
  }
  
  export class ParsingError extends AccessError {
    constructor (key: string, value: Primitive | null) {
      super(PARSING_FAILED_FOR(key, value as string));
    }
  }
  
  export class NoKeyError extends AccessError {
    constructor () {
      super(NO_KEY_PROVIDED);
    }
  }

  export class NoValueError extends AccessError {
    constructor () {
      super(NO_VALUE_PROVIDED);
    }
  }
  
  export class StorageUnavailableError extends AccessError {
    constructor () {
      super(LOCALSTORAGE_UNAVAILABLE);
    }
  }
  
  export class UnknownError extends AccessError {
    constructor () {
      super(UNKNOWN);
    }
  }

}