import { Pipe, PipeTransform } from '@angular/core';

import { getImagePath } from '../utils';

@Pipe({
  name: 'mpImagePath',
  standalone: true,
})
export class ImagePathPipe implements PipeTransform {
  transform(imagePath: string): string {
    return getImagePath(imagePath);
  }
}
