import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { HeaderContentService } from '../header-content.service';
import { HeaderElementPlacement } from '../models';

interface HeaderElementTemplateContext {
  // Active module name
  $implicit: string;
}

@Directive({
  selector: '[mpHeaderElement]',
  standalone: true,
})
export class HeaderElementDirective implements OnInit, OnDestroy {
  @Input() set mpHeaderElement(elementPlacement: HeaderElementPlacement | '') {
    this._placement = elementPlacement || 'left';
  }

  @Input() mpHeaderElementAppend = false;

  private _placement: HeaderElementPlacement = 'left';

  constructor(
    private readonly headerContentService: HeaderContentService,
    private readonly elementTemplateRef: TemplateRef<HeaderElementTemplateContext>,
  ) {}

  static ngTemplateContextGuard(
    _directive: HeaderElementDirective,
    context: unknown,
  ): context is HeaderElementTemplateContext {
    return true;
  }

  ngOnInit(): void {
    this.headerContentService.registerHeaderElement(
      this.elementTemplateRef,
      this._placement,
      this.mpHeaderElementAppend,
    );
  }

  ngOnDestroy(): void {
    this.headerContentService.clearHeaderElement(this.elementTemplateRef, this._placement);
  }
}
