import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  readonly isSmallViewport$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 840px)'])
    .pipe(map((state: BreakpointState) => !state.matches));

  readonly isMediumViewport$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 1280px)'])
    .pipe(map((state: BreakpointState) => !state.matches));

  constructor(private readonly breakpointObserver: BreakpointObserver) {}
}
