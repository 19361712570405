import { HttpParams } from '@angular/common/http';

import { Overwrite } from 'utility-types';

import {
  FilteringOperator,
  FilteringOperatorType,
  SortingDirection,
  SortingDirectionType,
  WithoutInclusions,
} from './query-params-types';

// eslint-disable-next-line @typescript-eslint/ban-types
type ObjectType = object;
type StringKeyof<T, R = T> = keyof T & keyof R & string;
type WithFieldsIncluded<
  Full extends ObjectType,
  Reduced extends ObjectType,
  Fields extends StringKeyof<Full>
> = Overwrite<Reduced, Pick<Full, Fields>>;

type ParamConfig = { preserveUndefined: boolean; preserveNull: boolean };

const DEFAULT_CONFIG: ParamConfig = {
  preserveUndefined: false,
  preserveNull: true,
} as const;
const DEFAULT_PAGE_SIZE = 25 as const;

export class QueryParams<
  Type extends ObjectType,
  ReducedType extends ObjectType = WithoutInclusions<Type>
> {
  /**
   * This field does not serve any technical purpose, but can be used to check
   * which fields exist on the reduced type of a QueryParams instance.
   *
   * Simply appending ['type?'] after an instance will prompt all properies inside intellisense.
   */
  'type?'?: ReducedType = undefined;

  private pageToQuery?: number;
  private pageSizeToQuery?: number;
  private paginationDisabled = false;

  private picks = new Set<string>();
  private inclusions = new Set<string>();

  private sortings = new Map<string, SortingDirectionType>();
  private filters = new Map<string, Set<string>>();

  private params = new Map<
    string,
    string | number | boolean | Array<string | number | boolean>
  >();

  static build<T extends ObjectType>(): QueryParams<T, WithoutInclusions<T>> {
    return new QueryParams<T, WithoutInclusions<T>>();
  }

  static copy<T extends ObjectType, R extends ObjectType>(
    paramsToCopy: QueryParams<T, R>
  ): QueryParams<T, R> {
    const copy = QueryParams.build<T>().as<R>();

    copy.pageToQuery = paramsToCopy.pageToQuery;
    copy.pageSizeToQuery = paramsToCopy.pageSizeToQuery;
    copy.paginationDisabled = paramsToCopy.paginationDisabled;
    copy.picks = paramsToCopy.picks;
    copy.inclusions = paramsToCopy.inclusions;
    copy.sortings = paramsToCopy.sortings;
    copy.filters = paramsToCopy.filters;

    return copy;
  }

  /**
   * Allows the developer to overwrite the QueryParams typing.
   * @description
   * During the chaining of method calls on the instance, typing operations are chained in a similar fashion.
   * This causes the resulting type at the end of the object construction to be unreadable in almost every case.
   * To prevent this and increase readability, this typing can be overwritten using the passed generic.
   * @summary
   * It is bad practice to pass any type that does NOT match the actual underlying type!
   *
   * This does NOT have any effect on the object's properties!
   */
  as<R extends ObjectType>(): QueryParams<Type, R> {
    return this as unknown as QueryParams<Type, R>;
  }

  select<Fields extends StringKeyof<ReducedType>>(
    ...fields: Array<Fields>
  ): QueryParams<Type, Pick<ReducedType, Fields>> {
    return this.buildReducedCopy(fields);
  }

  include<Field extends StringKeyof<Type, ReducedType>>(
    ...fields: Array<Field>
  ): QueryParams<Type, WithFieldsIncluded<Type, ReducedType, Field>> {
    this.addInclusions(...fields);

    return this as QueryParams<
      Type,
      WithFieldsIncluded<Type, ReducedType, Field>
    >;
  }

  /**
   * Allows the inclusion of fields without typing restrictions.
   * @description
   * Since in reality fields might include dots or aren't even properies of the generic,
   * this method allows the untyped inclusion of properties.
   * @example
   * QueryParams
   *   .build<{ foo: string, bar: number }>()
   *   .include('foo') // only possible values: 'foo', 'bar'
   *   .unsafeInclude('bar', 'baz.clazz'); // all strings allowed
   */
  unsafeInclude(...fields: Array<string>): QueryParams<Type, ReducedType> {
    this.addInclusions(...fields);

    return this;
  }

  filter(
    fields: StringKeyof<ReducedType> | Array<StringKeyof<ReducedType>>,
    operator: FilteringOperator | FilteringOperatorType,
    values: string | Array<string>
  ): QueryParams<Type, ReducedType> {
    try {
      const fieldString =
        fields instanceof Array ? this.wrapInLogicalOr(fields) : fields;
      const valueString =
        values instanceof Array ? this.wrapInLogicalOr(values) : values;

      this.addFilter(fieldString, operator, valueString);
    } catch (error) {
      return this;
    }

    return this;
  }

  sort(
    field: StringKeyof<ReducedType>,
    direction:
      | SortingDirection
      | SortingDirectionType = SortingDirection.ASCENDING
  ): QueryParams<Type, ReducedType> {
    this.addSorting(field, direction);

    return this;
  }

  withoutPagination(disabled = true): QueryParams<Type, ReducedType> {
    this.paginationDisabled = disabled;

    return this;
  }

  /**
   * Although this method does not do anything, it can be used to show other devs,
   * that the endpoint used does NOT provide pagination functionalitites.
   */
  noPaginationSupported(): QueryParams<Type, ReducedType> {
    return this.withoutPagination(true);
  }

  pageSize(
    pageSize: number = DEFAULT_PAGE_SIZE
  ): QueryParams<Type, ReducedType> {
    this.pageSizeToQuery = pageSize;

    return this;
  }

  page(page = 1): QueryParams<Type, ReducedType> {
    this.pageToQuery = page;

    return this;
  }

  private addSorting(field: string, direction: SortingDirectionType): void {
    this.sortings.set(field, direction);
  }

  private addFilter(
    field: string,
    operator: FilteringOperatorType,
    value: string
  ): void {
    const existingFilters = this.filters.get(field);
    const comparisonString = `${operator}${value}`;

    if (existingFilters) {
      existingFilters.add(comparisonString);
    } else {
      this.filters.set(field, new Set<string>([comparisonString]));
    }
  }

  private setPicks(...picks: Array<string>): void {
    this.picks.clear();
    picks.forEach((pick) => {
      this.picks.add(pick);
    });
  }

  private addInclusions(...inclusions: Array<string>): void {
    inclusions.forEach((inclusion) => {
      this.inclusions.add(inclusion);
    });
  }

  private wrapInLogicalOr(arrayOfStrings: Array<string>): string {
    if (arrayOfStrings.length > 1) {
      return arrayOfStrings.join('|');
    }

    if (arrayOfStrings.length === 1) {
      return arrayOfStrings[0];
    }

    throw Error(`Array ${arrayOfStrings.toString()} should cannot be empty!`);
  }

  /**
   * Adds multiple key-value pairs by reading them from an object.
   * Analogous to the new HttpParams({ fromObject: { ... } }) constructor call. */
  param(
    paramMap: Record<
      string,
      | string
      | number
      | boolean
      | Array<string | number | boolean | undefined | null>
      | undefined
      | null
    >,
    config?: Partial<ParamConfig>
  ): QueryParams<Type, ReducedType>;
  /**
   * Adds a single key-value pair to the HttpParam map.
   */
  param(
    key: string,
    value: string | number | boolean | undefined | null,
    config?: Partial<ParamConfig>
  ): QueryParams<Type, ReducedType>;
  /**
   * Adds an array of values to the HttpParam map under the given key.
   *
   * This is done by calling .append() multiple times.
   * The resulting request will consequently have reoccuring keys and will look like this:
   *
   * ```http://<URL>?key=value1&key=value2&key=value3``` */
  param(
    key: string,
    value:
      | Array<string | number | boolean | undefined | null>
      | undefined
      | null,
    config?: Partial<ParamConfig>
  ): QueryParams<Type, ReducedType>;
  /**
   * Allows adding key-value pair to an internal HttpParam map.
   * This allows preparing service requests without having to call {@link toHttpParams} mid-way.
   *
   * NOTE: This currently only supports **SETTING** key-value pairs to the HttpParam map (not reading / removing).
   */
  param(
    key:
      | string
      | Record<
          string,
          | string
          | number
          | boolean
          | Array<string | number | boolean | undefined | null>
          | undefined
          | null
        >,
    value?:
      | (string | number | boolean | undefined | null)
      | Array<string | number | boolean | undefined | null>
      | Partial<ParamConfig>,
    config: Partial<ParamConfig> = DEFAULT_CONFIG
  ): QueryParams<Type, ReducedType> {
    const fullConfig = { ...DEFAULT_CONFIG, ...config };

    if (typeof key === 'string') {
      if (Array.isArray(value)) {
        // This equates to the third method signature.
        return this.addKeyValueArrayParam(key, value, fullConfig);
      } else {
        // This equates to the second method signature.
        return this.addKeyValueParam(
          key,
          value as string | number | boolean,
          fullConfig
        );
      }
    } else if (typeof key === 'object') {
      // This equates to the first method signature.
      return this.addParamObject(key, fullConfig);
    }

    return this;
  }

  private addKeyValueParam(
    key: string,
    value: string | number | boolean | undefined | null,
    { preserveUndefined, preserveNull }: ParamConfig
  ): QueryParams<Type, ReducedType> {
    if (value !== undefined && value !== null) {
      this.params.set(key, value);
    } else if (value === undefined && preserveUndefined) {
      this.params.set(key, 'undefined');
    } else if (value === null && preserveNull) {
      this.params.set(key, 'null');
    }

    return this;
  }

  private addKeyValueArrayParam(
    key: string,
    value: Array<string | number | boolean | undefined | null>,
    { preserveNull, preserveUndefined }: ParamConfig
  ): QueryParams<Type, ReducedType> {
    const arrayOfValues = value.filter((singleValue) => {
      if (singleValue === undefined) {
        return preserveUndefined;
      }
      if (singleValue === null) {
        return preserveNull;
      }
      return true;
    }) as Array<string | number | boolean>;

    this.params.set(key, arrayOfValues);
    return this;
  }

  private addParamObject(
    params: Record<
      string,
      | string
      | number
      | boolean
      | Array<string | number | boolean | undefined | null>
      | undefined
      | null
    >,
    config: ParamConfig
  ): QueryParams<Type, ReducedType> {
    Array.from(Object.entries(params)).forEach(([key, value]) =>
      Array.isArray(value)
        ? this.addKeyValueArrayParam(key, value, config)
        : this.addKeyValueParam(key, value, config)
    );

    return this;
  }

  toHttpParams(): HttpParams {
    const params: {
      pagination?: string;
      page?: string;
      pageSize?: string;
      select?: string;
      include?: string;
      sort?: string;
      filter?: string;
    } = {};

    if (this.paginationDisabled) {
      params.pagination = 'false';
    } else {
      if (this.pageToQuery !== undefined) {
        params.page = this.pageToQuery.toString();
      }

      if (this.pageSizeToQuery !== undefined) {
        params.pageSize = this.pageSizeToQuery.toString();
      }
    }

    if (this.picks.size) {
      params.select = this.commaSeparatedStringFromSet(this.picks);
    }

    if (this.inclusions.size) {
      params.include = this.commaSeparatedStringFromSet(this.inclusions);
    }

    if (this.sortings.size) {
      params.sort = this.formatSortings(this.sortings);
    }

    if (this.filters.size) {
      params.filter = this.formatFilters(this.filters);
    }

    const fromObject = { ...mapToObject(this.params), ...params };
    return new HttpParams({ fromObject });
  }

  private commaSeparatedStringFromSet(set: Set<string>): string {
    return Array.from(set).join(',');
  }

  private formatSortings(
    sortingMap: Map<string, SortingDirectionType>
  ): string {
    return Array.from(sortingMap)
      .map(
        ([field, direction]) => `${direction === '+' ? '' : direction}${field}`
      )
      .join(',');
  }

  private formatFilters(filterMap: Map<string, Set<string>>): string {
    return Array.from(filterMap)
      .map(([field, filter]) =>
        Array.from(filter)
          .map((filterString) => `${field}${filterString}`)
          .join(',')
      )
      .join(',');
  }

  private buildReducedCopy<T extends ObjectType, R extends ObjectType>(
    picks?: Array<StringKeyof<R>>
  ): QueryParams<T, R> {
    const picksAsSet = picks ? new Set(picks) : this.picks;
    const reducedParams = new QueryParams<T, R>()
      .page(this.pageToQuery)
      .pageSize(this.pageSizeToQuery)
      .withoutPagination(this.paginationDisabled);

    // calling `pick()` here would create recursion
    reducedParams.setPicks(...(picks ?? Array.from(this.picks)));

    this.reduceFilters(reducedParams.filters, this.filters, picksAsSet);
    this.reduceSortings(reducedParams.sortings, this.sortings, picksAsSet);
    this.reduceInclusions(
      reducedParams.inclusions,
      this.inclusions,
      picksAsSet
    );

    return reducedParams;
  }

  private reduceFilters(
    reducedFilters: Map<string, Set<string>>,
    filtersToReduce: Map<string, Set<string>>,
    picks: Set<string>
  ): void {
    picks.forEach((pick) => {
      const pickedValue = filtersToReduce.get(pick);

      if (pickedValue) {
        reducedFilters.set(pick, pickedValue);
      }
    });
  }

  private reduceSortings(
    reducedSortings: Map<string, SortingDirectionType>,
    sortingsToReduce: Map<string, SortingDirectionType>,
    picks: Set<string>
  ): void {
    picks.forEach((pick) => {
      const pickedValue = sortingsToReduce.get(pick);

      if (sortingsToReduce.has(pick)) {
        // Assuming pickedValue is present
        reducedSortings.set(pick, pickedValue as SortingDirectionType);
      }
    });
  }

  private reduceInclusions(
    reducedInclusions: Set<string>,
    inclusionsToReduce: Set<string>,
    picks: Set<string>
  ): void {
    picks.forEach((pick) => {
      if (inclusionsToReduce.has(pick)) {
        reducedInclusions.add(pick);
      }
    });
  }
}

function mapToObject(
  map: Map<string, string | number | boolean | Array<string | number | boolean>>
): {
  [key: string]:
    | string
    | number
    | boolean
    | ReadonlyArray<string | number | boolean>;
} {
  // Custom implementation of "Object.fromEntries(map);":
  return Array.from(map.entries()).reduce(
    (acc, [key, value]) => ({ ...acc, ...{ [key]: value } }),
    {}
  );
}
