import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, startWith } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { RouterEventsService } from '../services/router-events.service';

import { FeatureFlags } from './models';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsHandlerService {
  readonly featureFlags$: Observable<FeatureFlags> = this.routerEventsService.navigationEnd$.pipe(
    // Initial load of the application does not trigger the navigationEnd event
    startWith({}),
    map(() => this.getLeafRouteFeatureFlags()),
    distinctUntilChanged(),
    shareReplay(1),
  );

  constructor(private readonly routerEventsService: RouterEventsService, private readonly route: ActivatedRoute) {}

  private getLeafRouteFeatureFlags(): FeatureFlags {
    let featureFlags: FeatureFlags = {};

    for (let leafRoute: ActivatedRoute | null = this.route; leafRoute != null; leafRoute = leafRoute.firstChild) {
      featureFlags = { ...featureFlags, ...this.getRouteFeatureFlags(leafRoute) };
    }

    return featureFlags;
  }

  private getRouteFeatureFlags(route: ActivatedRoute): FeatureFlags {
    let featureFlags: FeatureFlags = {};

    if ('featureFlags' in route.snapshot.data) {
      featureFlags = route.snapshot.data['featureFlags'];
    }

    return featureFlags;
  }
}
