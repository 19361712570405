import { AfterViewInit, Directive } from '@angular/core';

import { RestoreScrollingTriggerService } from '../services';

@Directive({
  selector: '[mpRestoreScrollingTrigger]',
  standalone: true,
})
export class RestoreScrollingTriggerDirective implements AfterViewInit {
  constructor(private readonly restoreScrollingTriggerService: RestoreScrollingTriggerService) {}

  ngAfterViewInit(): void {
    this.restoreScrollingTriggerService.triggerScroll();
  }
}
