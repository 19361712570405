import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { concatMap, distinctUntilChanged, shareReplay } from 'rxjs/operators';

/**
 * Periodically calls a function every `interval` milliseconds and emits it's results on `AutoFetcher<T>.state$`.
 * Can be started with `AutoFetcher<T>.start()` and stopped with `AutoFetcher<T>.stop()`.
 *
 * This currently does NOT implement error handling! */
export class AutoFetcher<T> {
  timer$?: Observable<number>;
  private subscription?: Subscription;

  private readonly _state$: Subject<T>;
  readonly state$: Observable<T>;

  private state: T | undefined;
  get currentState(): T | undefined {
    return this.state;
  }

  constructor(initialValue?: T) {
    this._state$ = initialValue === undefined ? new Subject() : new BehaviorSubject<T>(initialValue);
    this.state$ = this._state$.pipe(shareReplay(1));

    this.state = initialValue;
  }

  start(callback: () => Observable<T>, interval: number, initialDelay = 0): void {
    this.timer$ = timer(initialDelay, interval);
    this.subscription = this.timer$
      .pipe(
        concatMap(() => callback()),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (result) => {
          this._state$.next(result);
          this.state = result;
        },
      });
  }

  stop(): void {
    this.subscription?.unsubscribe();
    this._state$.complete();
  }
}
