import { Observable, defaultIfEmpty } from 'rxjs';

import { UnsavedChangesAware } from './unsaved-changes-aware';

export function confirmUnsavedChangesGuard(component: UnsavedChangesAware): Observable<boolean> | boolean {
  const result: boolean | Observable<boolean> = component.confirmUnsavedChanges();

  if (typeof result === 'boolean') {
    return result;
  }

  return result.pipe(defaultIfEmpty(false));
}
