import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HeaderElementPlacement, HeaderElementsMap } from './models';

const INITIAL_HEADER_ELEMENTS_MAP_STATE: HeaderElementsMap = {
  left: [],
  center: [],
  right: [],
};

@Injectable({ providedIn: 'root' })
export class HeaderContentService {
  private readonly _headerElementsMap$: BehaviorSubject<HeaderElementsMap> = new BehaviorSubject<HeaderElementsMap>(
    INITIAL_HEADER_ELEMENTS_MAP_STATE,
  );
  readonly headerElementsMap$: Observable<HeaderElementsMap> = this._headerElementsMap$.asObservable();

  private get _currentHeaderElementsMap(): HeaderElementsMap {
    return this._headerElementsMap$.getValue();
  }

  registerHeaderElement(template: TemplateRef<unknown>, placement: HeaderElementPlacement, append: boolean): void {
    this.addHeaderElement(template, placement, append);

    this.emitUpdatedHeaderElementsMap();
  }

  clearHeaderElement(template: TemplateRef<unknown>, placement: HeaderElementPlacement): void {
    this.removeHeaderElementFromStack(template, placement);

    this.emitUpdatedHeaderElementsMap();
  }

  private addHeaderElement(template: TemplateRef<unknown>, placement: HeaderElementPlacement, append: boolean): void {
    const currentElements: TemplateRef<unknown>[] = this._currentHeaderElementsMap[placement];
    this._currentHeaderElementsMap[placement] = append ? [...currentElements, template] : [template];
  }

  private removeHeaderElementFromStack(template: TemplateRef<unknown>, placement: HeaderElementPlacement): void {
    this._currentHeaderElementsMap[placement] = this._currentHeaderElementsMap[placement].filter(
      (elementTemplate) => elementTemplate !== template,
    );
  }

  private emitUpdatedHeaderElementsMap(): void {
    this._headerElementsMap$.next({ ...this._currentHeaderElementsMap });
  }
}
