import { Injectable } from '@angular/core';

type ScrollPositions = Record<string, number>;

@Injectable({
  providedIn: 'root',
})
export class ScrollPositionService {
  private readonly key = 'ScrollTopPosition';

  get scrollPositions(): ScrollPositions {
    const storedScrollPositions: string | null = window.sessionStorage.getItem(this.key);
    return storedScrollPositions ? JSON.parse(storedScrollPositions) : {};
  }

  saveScrollPosition(obj: ScrollPositions): void {
    this.save({
      ...this.scrollPositions,
      ...obj,
    });
  }

  private save(obj: ScrollPositions): void {
    window.sessionStorage.setItem(this.key, JSON.stringify(obj));
  }
}
