import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class FormsStore<S> {
  private readonly store$: BehaviorSubject<S>;

  constructor(state: S) {
    this.store$ = new BehaviorSubject(state);
  }

  select<R>(project: (store: S) => R): Observable<R> {
    return this.store$.asObservable().pipe(map(project), distinctUntilChanged());
  }

  getValue(): S {
    return this.store$.getValue();
  }

  set(state: S): void {
    this.store$.next(state);
  }

  update(state: Partial<S>): void {
    this.store$.next({ ...this.getValue(), ...state });
  }
}
