import { AbstractControl } from '@angular/forms';

import { Primitive } from 'utility-types';

export class FormEvaluator {

  constructor(private readonly emptyValue: any = null) { }

  value<R>(control: AbstractControl): R {
    return this.transform(control.value);
  }

  private transform(obj: any): any {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return this.transformArray(obj);
    } else if (typeof obj === 'object') {
      return this.transformObject(obj);
    } else {
      return this.transformPrimitive(obj);
    }
  }

  private transformObject(object: Record<string, unknown>): Record<string, unknown> {
    for (const [key, value] of Object.entries(object)) {
      object[key] = this.transform(value);
    }

    return object;
  }

  private transformArray(array: Array<any>): Array<any> {
    for (const [index, value] of array.entries()) {
      array[index] = this.transform(value);
    }

    return array;
  }

  private transformPrimitive(primitive: Primitive): Primitive {
    return primitive === '' ? this.emptyValue : primitive;
  }
}
