import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'autoSingularOrPlural' })
export class AutoGrammaticalNumberLabelPipe implements PipeTransform {
  transform(count: undefined, singular: string, plural?: string): undefined;
  transform(count: number, singular: string, plural?: string): string;
  transform(count: number | undefined, singular: string, plural = singular): string | undefined {
    if (count === undefined) {
      return undefined;
    }

    const pluralForm = plural.startsWith('-') ? singular + plural.substring(1) : plural;

    this.checkGrammaticalNumberLengths(singular, pluralForm);

    return `${count.toLocaleString('de-DE')} ${count === 1 ? singular : pluralForm}`;
  }

  private checkGrammaticalNumberLengths(singular: string, plural: string): void {
    if (singular.length === 0) {
      console.warn(`Singular form "${singular}" has a length of 0!`);
    }

    if (plural.length === 0) {
      console.warn(`Plural form "${plural}" has a length of 0!`);
    }
  }
}
