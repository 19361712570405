const ADDITIONAL_DATA_KEY = '$json';

export function wrapInImageFormData<ImageProperty extends string>(
  image: File | string | null | undefined,
  imagePropertyName: ImageProperty,
  additionalData: Record<string, unknown> & { [P in ImageProperty]?: never },
): FormData {
  const formData: FormData = new FormData();
  formData.append(ADDITIONAL_DATA_KEY, JSON.stringify(additionalData));

  if (image instanceof File) {
    formData.append(imagePropertyName, image, image.name);
  } else if (image === '') {
    formData.append(imagePropertyName, image);
  }

  return formData;
}
