import { Directive, ElementRef, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { CountUp } from 'countup.js';
import { CountUpOptions } from './count-up-options';

@Directive({
  selector: '[mpCountUp]',
  standalone: true,
})
export class CountUpDirective implements OnChanges {
  @Input('mpCountUp') endVal!: number;

  @Input() options: CountUpOptions = {};

  private countUp!: CountUp;

  constructor(private el: ElementRef, private zone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { options, endVal } = changes;
    if (endVal?.currentValue !== undefined || options?.currentValue !== undefined) {
      if (this.countUp !== undefined) {
        this.updateCountUp();
      } else {
        this.initAndRun();
      }
    }
  }

  private updateCountUp(): void {
    this.zone.runOutsideAngular(() => {
      this.countUp.update(this.endVal);
    });
  }

  private initAndRun(): void {
    this.zone.runOutsideAngular(() => {
      this.countUp = new CountUp(this.el.nativeElement, this.endVal, this.options);
      this.countUp.reset();
      this.countUp.start();
    });
  }
}
