import { Pipe, PipeTransform } from '@angular/core';

export type TypeGuard<A, B extends A> = (a: A) => a is B;

export type TypeGuards<A, K extends keyof A, P extends A[K] & (string | number) = A[K] & (string | number)> = {
  [T in P]: TypeGuard<A, Extract<A, Record<K, T>>>;
}

/**
 * Pipe to use with ngIf statements to narrow types.
 *
 * @example
 * <span *ngIf="animal | guard:isCat as cat">{{ cat.meow }}</span>
 *
 * @see
 * See https://spin.atomicobject.com/ngswitch-type-errors/
 */
@Pipe({
  name: 'guard',
  standalone: true,
})
export class GuardPipe implements PipeTransform {
  transform<A, B extends A>(value: A | null | undefined, guard: TypeGuard<A, B>): B | undefined {
    return value != null && guard(value) ? value : undefined;
  }
}
