import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterEventsService {
  readonly navigationStart$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationStart),
    map((event) => event as NavigationStart)
  );

  readonly navigationEnd$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => event as NavigationEnd)
  );

  readonly navigationCancel$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationCancel),
    map((event) => event as NavigationCancel)
  );

  readonly navigationError$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationError),
    map((event) => event as NavigationError)
  );

  readonly navigationEndCancelError$ = merge(
    this.navigationEnd$,
    this.navigationCancel$,
    this.navigationError$
  );

  constructor(private router: Router) {}
}
