import { Observable, of } from 'rxjs';

export function observeElementInViewport(
  element: HTMLElement,
  threshold?: number | Array<number>,
  rootMargin?: string
): Observable<true> {
  if (typeof IntersectionObserver !== 'function') {
    return of(true);
  }

  return new Observable<true>((observer) => {
    const io = new IntersectionObserver(
      (entries) => {
        const entry = entries[entries.length - 1];
        const isIntersecting =
          entry.isIntersecting || entry.intersectionRatio > 0;
        if (isIntersecting) {
          observer.next(true);
          observer.complete();
        }
      },
      { threshold, rootMargin }
    );

    io.observe(element);

    return () => {
      io.disconnect();
    };
  });
}
