export enum FileTypes {
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  PNG = 'image/png',
}

export const DEFAULT_ALLOWED_TYPES: FileTypes[] = [FileTypes.JPEG, FileTypes.PNG];

export function isOfGivenImageTypes(filetype: string, allowedTypes: string[]): boolean {
  return allowedTypes.includes(filetype);
}

export function isImageType(filetype: string): boolean {
  return filetype.split('/')[0] === 'image';
}

export function isImageValid(imageFile: File, allowedTypes: string[]): boolean {
  if (allowedTypes && !isOfGivenImageTypes(imageFile.type, allowedTypes)) {
    return false;
  }

  return isImageType(imageFile.type);
}
