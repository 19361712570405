import { Observable, OperatorFunction } from 'rxjs';


function notNil<T>(value: T | undefined | null): value is Exclude<T, null | undefined> { return value !== undefined && value !== null; }
function notNull<T>(value: T | null): value is T { return value !== null; }
function notUndefined<T>(value: T | undefined): value is T { return value !== undefined; }


export function filterNil<T>(): OperatorFunction<T, Exclude<T, null | undefined>>{
  return function(source$: Observable<T>): Observable<Exclude<T, null | undefined>> {
    return new Observable(subscriber => {
      return source$.subscribe({
        next(value) { if (notNil(value)) { subscriber.next(value); } },
        error(error: unknown) { subscriber.error(error); },
        complete() { subscriber.complete(); }
      });
    });
  };
}

export function filterNull() {
  return function<T>(source$: Observable<T | null>): Observable<T> {
    return new Observable(subscriber => {
      return source$.subscribe({
        next(value) { if (notNull(value)) { subscriber.next(value); } },
        error(error: unknown) { subscriber.error(error); },
        complete() { subscriber.complete(); }
      });
    });
  };
}

export function filterUndefined() {
  return function<T>(source$: Observable<T | undefined>): Observable<T> {
    return new Observable(subscriber => {
      return source$.subscribe({
        next(value) { if (notUndefined(value)) { subscriber.next(value); }},
        error(error: unknown) { subscriber.error(error); },
        complete() { subscriber.complete(); }
      });
    });
  };
}
