import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ControlSnapshot } from '../typed-wrappers/control-snapshot';
import { Form } from '../typed-wrappers/form';
import { FormsMap } from '../utils/forms-map';
import { FormsStore } from '../utils/forms-store';
import { TypedForm } from '../typed-wrappers/typed-form';
import { TypedFormArray } from '../typed-wrappers/typed-form-array';
import { TypedFormCollection } from '../typed-wrappers/typed-form-collection';

type TypedFormConfig<T> = {
  name: T,
  control: AbstractControl,
  containsInitialValue?: boolean,
  destroyWhen$?: Observable<any>
};

/**
 * Store-based Service for managing all forms within the application.
 * Provides basic methods for form management and convenience methods.
 */
@Injectable({ providedIn: 'root' })
export class FormsManager<FormsState = any> {

  private readonly store: FormsStore<FormsState>;
  private readonly formsMap: FormsMap<FormsState>;

  private readonly _formDestroyed$ = new Subject<keyof FormsState | 'ALL'>();

  constructor() {
    this.store = new FormsStore({} as unknown as FormsState);
    this.formsMap = new FormsMap();
  }

  /**
   * Returns the validity of a form.
   * @see TypedForm#isValid
   * @example
   * // validity of a form
   * formsManager.isValid('contact-form');
   */
  isValid(name: keyof FormsState): boolean;
  /**
   * Returns the validity of a control within a form.
   * If no control exists under the given path, `null` is returned.
   * @see TypedForm#isValid
   * @example
   * // validity of a control within a form
   * formsManager.isValid('contact-form', 'email');
   * formsManager.isValid('contact-form', 'notiz.content');
   */
  isValid(name: keyof FormsState, path: string): boolean | null;
  isValid(name: keyof FormsState, path?: string): boolean | null {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form?.isValid(path as any);
  }

  /**
   * Returns the value of a form.
   * NOTE: Due to issues with Angulars poor forms implementation and a conflict with our backend,
   *       **the forms value is transformed before being returned**.
   *       This results in differences between {@link value}, {@link valueChanges$} and {@link snapshot}.
   *       This is the current design and might change in the future.
   * @see TypedForm#value
   * @example
   * // value of a form
   * formsManager.value('contact-form');
   */
  value<T extends keyof FormsState>(name: T): FormsState[T];
  /**
   * Returns the value of a control within a form.
   * If no control exists under the given path, `null` is returned.
   * NOTE: Due to issues with Angulars poor forms implementation and a conflict with our backend,
   *       **the forms value is transformed before being returned**.
   *       This results in differences between {@link value}, {@link valueChanges$} and {@link snapshot}.
   *       This is the current design and might change in the future.
   * @see TypedForm#value
   * @example
   * // value of a control within a form
   * formsManager.value('contact-form', 'email');
   *
   * // values can also be typed
   * formsManager.value<number>('contact-form', 'plz');
   */
  value<R = any>(name: keyof FormsState, path: string): R;
  value<T extends keyof FormsState, R = FormsState[T]>(name: T, path?: string): R {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.value(path as any);
  }

  /**
   * Sets the value of a form.
   * This is done analogously to the Angular implmenentation.
   * @see TypedForm#setValue
   * @example
   * // sets the value of a form
   * const exactValue = { email: '...', telefon: '...' };
   * formsManager.setValue('contact-form', exactValue);
   */
  setValue<T extends keyof FormsState>(name: T, value: FormsState[T]): void;
  /**
   * Sets the value of a control within a form.
   * This is done analogously to the Angular implmenentation.
   * @see TypedForm#setValue
   * @example
   * // sets the value of a control within a form
   * const exactValue = '...';
   * formsManager.setValue('contact-form', exactValue, 'email');
   */
  setValue<T>(name: keyof FormsState, value: T, path: string): void;
  setValue<T>(name: keyof FormsState, value: T, path?: string): void {
    const form: TypedForm = this.getFormOrThrowError(name);
    form.setValue(value, path as any);
  }

  /**
   * Patches the value of a form.
   * This is done analogously to the Angular implmenentation.
   * @see TypedForm#patchValue
   * @example
   * // patches the value of a form
   * const valueLike = { email: '...', foo: -3 }
   * formsManager.patchValue('contact-form', valueLike);
   */
  patchValue(name: keyof FormsState, value: any): void;
  /**
   * Patches the value of a control within a form.
   * This is done analogously to the Angular implmenentation.
   * @see TypedForm#patchValue
   * @example
   * // patches the value of a control within a form
   * const valueLike = '...';
   * formsManager.patchValue('contact-form', valueLike, 'email');
   */
  patchValue(name: keyof FormsState, value: any, path: string): void;
  patchValue(name: keyof FormsState, value: any, path?: string): void {
    const form: TypedForm = this.getFormOrThrowError(name);
    form.patchValue(value, path as any);
  }

  /**
   * Sets the initial value of a form using the forms current value.
   * This initial value is used as reference during the change detection.
   * @see FormsManager#initialValueChanged$
   * @example
   * // sets a forms initial value that is used as a baseline during change-detection
   * formsManager.setValue('contact-form', { email: '...' });
   *
   * formsManager.setInitialValue('contact-form');
   * // initialValue is now `{ email: '...' }`
   */
  setInitialValue(name: keyof FormsState): void {
    this.getForm(name)?.setInitialValue();
  }

  /**
   * Emits the current dirty state of a form.
   * This is `false` whenever the forms value is equal to the forms initial value
   * and `true` otherwise.
   * NOTE: This will only emit values, when {@link setInitialValue} was called before!
   * @see setInitialValue
   * @example
   * // emits the dirty state of a form
   * formsManager.initialValueChanged$('contact-form').subscribe(changed => { ... });
   */
  initialValueChanged$(name: keyof FormsState): Observable<boolean>;
  /**
   * Emits the current dirty state of a control within a form.
   * This is `false` whenever the controls value is equal to the controls initial value
   * and `true` otherwise.
   * NOTE: This will only emit values, when {@link setInitialValue} was called before!
   * @see setInitialValue
   * @example
   * // emits the dirty state of a control within a form
   * formsManager.initialValueChanged$('contact-form', 'email').subscribe(changed => { ... });
   */
  initialValueChanged$(name: keyof FormsState, path: string): Observable<boolean>;
  initialValueChanged$(name: keyof FormsState, path?: string): Observable<boolean> {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.initialValueChanged$(path as any);
  }

  /**
   * Emits the current validity of a form.
   * @example
   * // emits the validity of a form
   * formsManager.validityChanges$('contact-form').subscribe(changed => { ... });
   */
  validityChanges$(name: keyof FormsState): Observable<boolean>;
  /**
   * Emits the current validity of a control within a form.
   * @example
   * // emits the validity of a control within a form
   * formsManager.validityChanges$('contact-form', 'email').subscribe(changed => { ... });
   */
  validityChanges$(name: keyof FormsState, path: string): Observable<boolean>;
  validityChanges$(name: keyof FormsState, path?: string): Observable<boolean> {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.validityChanges$(path as any);
  }

  /**
   * Emits the value of a form.
   * **NOTE**: This will emit the *actual* value of a form.
   *           Values emitted are NOT transformed by a FormEvaluator.
   *           (meaning empty strings are not replaced by null)
   * @see AbstractControl#valueChanges
   * @example
   * // emits the value of a form
   * formsManager.valueChanges$('contact-form').subscribe(changed => { ... });
   */
  valueChanges$<T = any>(name: keyof FormsState): Observable<T>;
  /**
   * Emits the value of a control within a form.
   * **NOTE**: This will emit the *actual* value of a form.
   *           Values emitted are NOT transformed by a FormEvaluator.
   *           (meaning empty strings are not replaced by null)
   * @see AbstractControl#valueChanges
   * @example
   * // emits the value of a control within a form
   * formsManager.valueChanges$('contact-form', 'email').subscribe(changed => { ... });
   */
  valueChanges$<T = any>(name: keyof FormsState, path: string): Observable<T>;
  valueChanges$<T extends keyof FormsState>(name: T, path?: string): Observable<FormsState[T]>;
  valueChanges$(name: keyof FormsState, path?: string): Observable<any> {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.valueChanges$(path as any);
  }

  /**
   * Emits the `errors`-object of a form.
   * @see AbstractControl#errors
   * @example
   * // emits the errors of a form
   * formsManager.errorsChanges$('contact-form').subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(name: keyof FormsState): Observable<Errors>;
  /**
   * Emits the `errors`-object of a control within a form.
   * @see AbstractControl#errors
   * @example
   * // emits the errors of a control within a form
   * formsManager.errorsChanges$('contact-form', 'email').subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(name: keyof FormsState, path: string): Observable<Errors>;
  errorsChanges$<Errors = any>(name: keyof FormsState, path?: string): Observable<Errors> {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.errorsChanges$(path as any);
  }

  /**
   * Checks if a form has a control registered under a given path.
   * Returns `true` if a control is registered under that path for that form
   * and `false` otherwise.
   * @example
   * // checks if a control exists within a form
   * formsManager.hasControl('contact-form', 'email');
   */
  has(name: keyof FormsState, path: string): boolean {
    const form: TypedForm | null = this.getForm(name);
    return form ? form.has(path) : false;
  }

  /**
   * Gets a specific collection from the manager.
   * If no collection was registered under the given name, `null` is returned.
   * @see TypedFormCollection
   * @example
   * // gets a registered collection
   * formsManager.getCollection('forms-collection');
   */
  getCollection<T extends keyof FormsState>(name: T): TypedFormCollection<FormsState[T]> | null {
    return this.formsMap.getFormCollection(name) ?? null;
  }

  /**
   * Gets a specific form from the manager.
   * If no form was registered under the given name, `null` is returned.
   * @see TypedForm
   * @example
   * // gets a registered form
   * formsManager.getForm('contact-form');
   */
  getForm<T extends keyof FormsState>(name: T): TypedForm<FormsState[T]> | null {
    return this.formsMap.getForm(name) as any;
  }

  private getFormOrThrowError<T extends keyof FormsState>(name: T): TypedForm {
    const form: TypedForm | null = this.getForm(name);
    if (!form) {
      throw Error('No form with given name!');
    }

    return form;
  }

  /**
   * Gets a specific control from a form.
   * Returns the forms underlying FormGroup if no further path is specified.
   * If no form was registered under the given name, `undefined` is returned.
   * If no control was registered under the given path, `null` is returned.
   * @see TypedForm#get
   * @example
   * // gets a control within a form
   * formsManager.get('contact-form', 'email');
   */
  get<R extends AbstractControl>(name: keyof FormsState, path?: string): R | undefined | null {
    return !path ?
      this.getForm(name)?.control as R :
      this.getForm(name)?.get(path);
  }

  /**
   * Gets a FormArray from a form and wraps it within a typed wrapper.
   * If no form was registered under the given name, `undefined` is returned.
   * If no control was registered under the given path, `null` is returned.
   * @see TypedFormArray
   * @example
   * // gets a FormArray from the form and wraps it for convenience
   * const adressen = formsManager.getArray('contact-form', 'adressen');
   */
  getArray<T = any>(name: keyof FormsState, path: string): TypedFormArray<T> | null {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.getArray(path);
  }

  /**
   * Takes a snapshot of a form.
   * @see ControlSnapshot
   * @example
   * // gets a forms snapshot
   * const snapshot = formManager.snapshot('contact-form');
   */
  snapshot<T extends keyof FormsState>(name: T): ControlSnapshot<FormsState[T]> | null;
  /**
   * Takes a snapshot of a control within a form.
   * @see ControlSnapshot
   * @example
   * // gets a controls snapshot
   * const snapshot = formManager.snapshot('contact-form', 'email');
   */
  snapshot<State = any>(name: keyof FormsState, path: string): ControlSnapshot<State> | null;
  snapshot<T extends keyof FormsState>(name: T, path?: string): ControlSnapshot<FormsState[T]> | null;
  snapshot(name: keyof FormsState, path?: string): ControlSnapshot | null {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.snapshot(path);
  }

  /**
   * Emits the snapshot of a form.
   * NOTE: This observable is fired whenever ANY kind of change occurs (value or state).
   * @see ControlSnapshot
   * @see TypedForm#controlChanges$
   * @example
   * // subscribes to any state changes
   * formsManager.controlChanges$('contact-form').subscribe(snapshot => { ... });
   */
  controlChanges$<T = any>(name: keyof FormsState): Observable<ControlSnapshot<T>>;
  /**
   * Emits the snapshot of a control within a form.
   * NOTE: This observable is fired whenever ANY kind of change occurs (value or state).
   * @see ControlSnapshot
   * @see TypedForm#controlChanges$
   * @example
   * // subscribes to any state changes
   * formsManager.controlChanges$('contact-form', 'email').subscribe(snapshot => { ... });
   */
  controlChanges$<T = any>(name: keyof FormsState, path: string): Observable<ControlSnapshot<T>>;
  controlChanges$<T = any>(name: keyof FormsState, path?: string): Observable<ControlSnapshot<T>> {
    const form: TypedForm = this.getFormOrThrowError(name);
    return form.controlChanges$(path as any);
  }

  /**
   * Creates a new TypedForm and registers it with the manager.
   * If a form with the given name already exists, an error is thrown.
   * @see TypedForm
   * @example
   * // creates and registers a new form
   * const formGroup = fb.group({ email: [null] });
   * formsManager.createForm('contact-form', formGroup);
   *
   * // the form can also be stored for later use
   * const form = formManager.createForm('contact-form', formGroup);
   */
  createForm<T extends keyof FormsState>(
    name: T,
    control: AbstractControl
  ): TypedForm<FormsState[T]> {
    return this.createFormFromConfig({ name, control });
  }

  /**
   * Creates a new TypedForm using a config and registers it with the manager.
   * If a form with the given name already exists, an error is thrown.
   * @see TypedForm
   * @example
   * // creates and registers a new form
   * const formGroup = fb.group({ email: [null] });
   * const formConfig = {
   *   name: 'contact-form',
   *   control: formGroup,
   *   containsInitialValue: false,
   *   destroyWhen$: this.isDestroyed$
   * };
   * formsManager.createFormFromConfig(formConfig);
   *
   * // the form can also be stored for later use
   * const form = formManager.createFormFromConfig(formConfig);
   */
  createFormFromConfig<T extends keyof FormsState>(
    config: TypedFormConfig<T>
  ): TypedForm<FormsState[T]> {

    if (this.hasForm(config.name)) {
      throw Error(`Form ${ String(config.name) } already exists!`);
    }

    const newForm = this.buildForm(config);
    this.formsMap.registerForm(newForm);
    this.attachDestroyListener(newForm);

    if (config.destroyWhen$) {
      newForm.destroyWhen(config.destroyWhen$);
    }

    return newForm;
  }

  private buildForm<T extends keyof FormsState>(
    config: TypedFormConfig<T>
  ): TypedForm<FormsState[T]> {
    return new TypedForm<FormsState[T]>(
      config.name,
      config.control,
      this.store,
      config.containsInitialValue ?? false
    );
  }

  /**
   * Creates a new TypedFormCollection and registers it with the manager.
   * @see TypedFormCollection
   * @example
   * // creates and registers a new form collection
   * formsManager.createFormCollection('form-collection', ['form-#1', 'form-#2', 'form-#3']);
   *
   * // the collection can also be stored for later use
   * const collection = formsManager.createFormCollection('form-collection', ['form-#1', 'form-#2', 'form-#3']);
   */
  createFormCollection<T = FormsState>(
    name: keyof FormsState,
    formNames: Array<keyof FormsState>
  ): TypedFormCollection<T> {
    return this.createFormCollectionFromConfig({ name, formNames });
  }

  /**
   * Creates a new TypedFormCollection using a config and registers it with the manager.
   * @see TypedFormCollection
   * @example
   * // creates and registers a new form collection
   * const formConfig = {
   *   name: 'form-collection',
   *   formNames: ['form-#1', 'form-#2', 'form-#3'],
   *   containsInitialValue: false,
   *   destroyWhen$: this.isDestroyed$
   * };
   * formsManager.createFormCollectionFromConfig(formConfig);
   *
   * // the collection can also be stored for later use
   * const collection = formsManager.createFormCollectionFromConfig(formConfig);
   */
  createFormCollectionFromConfig<T = FormsState>(
    config: {
      name: keyof FormsState,
      formNames: Array<keyof FormsState>,
      containsInitialValue?: boolean,
      destroyWhen$?: Observable<any>
    }
  ): TypedFormCollection<T> {

    const newCollection = this.buildFormCollection<T>(config);
    this.formsMap.registerFormCollection(newCollection);
    this.attachDestroyListener(newCollection);

    if (config.destroyWhen$) {
      newCollection.destroyWhen(config.destroyWhen$);
    }

    return newCollection;
  }

  private buildFormCollection<T = FormsState>(
    config: {
      name: keyof FormsState,
      formNames: Array<keyof FormsState>,
      containsInitialValue?: boolean,
      destroyWhen$?: Observable<any>
    }
  ): TypedFormCollection<T> {
    return new TypedFormCollection<T>(
      config.name,
      config.formNames,
      this.formsMap,
      config.containsInitialValue ?? false
    );
  }

  private attachDestroyListener(form: Form): void {
    form.destroy$
      .pipe(take(1))
      .subscribe({ next: name => { this.destroy(name as keyof FormsState); } });
  }

  /**
   * Checks if a form is registered under the given name.
   * Returns `true` if a form is registered under the given name
   * and `false` otherwise.
   * NOTE: This is specific to TypedForms.
   *       This will return `false` for any registered TyedFormCollection.
   * @see TypedForm
   * @example
   * // checks if a form exists
   * formsManager.hasForm('contact-form');
   */
  hasForm(name: keyof FormsState): boolean {
    return this.formsMap.hasForm(name);
  }

  /**
   * Checks if a form collection is registered under the given name.
   * Returns `true` if a form collection is registered under the given name
   * and `false` otherwise.
   * NOTE: This is specific to TypedFormCollections.
   *       This will return `false` for any registered TyedForm.
   * @see TypedFormCollection
   * @example
   * // checks if a collection exists
   * formsManager.hasFormCollection('form-collection');
   */
  hasFormCollection(name: keyof FormsState): boolean {
    return this.formsMap.hasFormCollection(name);
  }

  /**
   * Destroys all forms registered with the manager.
   * @see TypedForm#destroy
   * @example
   * formsManager.destroy();
   */
  destroy(): void;
  /**
   * Destroys a form registered with the manager.
   * @example
   * @see TypedForm#destroy
   * formsManager.destroy('contact-form');
   */
  destroy(name: keyof FormsState): void;
  destroy(name?: keyof FormsState): void {
    if (!name) {
      this._formDestroyed$.next('ALL');
      this.formsMap.clear();
    } else {
      this._formDestroyed$.next(name);

      if (this.hasFormCollection(name)) {
        this.getCollection(name)?.destroy();
        this.formsMap.unregisterFormCollection(name);
      } else {
        this.getForm(name)?.destroy();
        this.formsMap.unregisterForm(name);
      }
    }
  }

  /**
   * Emits when a form with the given name is destroyed.
   * @see destroy
   * @example
   * formsManager.formDestroyed$('contact-form').subscribe(() => { ... });
   */
  formDestroyed$(name: keyof FormsState): Observable<void> {
    return this._formDestroyed$.pipe(
      filter(target => target === name || target === 'ALL'),
      map(() => { /* void */ })
    );
  }

}
