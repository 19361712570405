import { Injectable } from '@angular/core';

interface IPrettyPrintListPipeIntl {
  separator: string;
  
  and: string;

  moreSingular: string;
  morePlural: string;
  more?: (count: number) => string;
}

@Injectable()
export abstract class PrettyPrintListPipeIntl implements IPrettyPrintListPipeIntl {
  abstract separator: string;
  abstract and: string;
  abstract moreSingular: string;
  abstract morePlural: string;
  more?: ((count: number) => string) | undefined;
}

@Injectable()
export class PrettyPrintListPipeIntlGerman extends PrettyPrintListPipeIntl {
  separator = ',';
  and = 'und';
  moreSingular = 'Weitere';
  morePlural = 'Weitere';
}

@Injectable()
export class PrettyPrintListPipeIntlEnglish extends PrettyPrintListPipeIntl {
  separator = ',';
  and = 'and';
  moreSingular = 'more';
  morePlural = 'more';
}
