import { Overwrite, PickByValue } from 'utility-types';

export type FilteringOperatorType = `${FilteringOperator}`;
export enum FilteringOperator {
  EQUALS = '==',
  NOT_EQUALS = '!=',
  EQUALS_CS = '==*',

  GREATER = '>',
  LESS = '<',
  INCLUDING_GREATER = '>=',
  INCLUDING_LESS = '<=',

  CONTAINS = '@=',
  NOT_CONTAINS = '!@=',
  CONTAINS_CS = '@=*',
  NOT_CONTAINS_CS = '!@=*',

  STARTS_WITH = '_=',
  NOT_STARTS_WITH = '!_=',
  STARTS_WITH_CS = '_=*',
  NOT_STARTS_WITH_CS = '!_=*'
}


export type SortingDirectionType = `${SortingDirection}`;
export enum SortingDirection {
  ASCENDING = '+',
  DESCENDING = '-'
}


// eslint-disable-next-line @typescript-eslint/ban-types
type ObjectType = object;
export type WithoutInclusions<T extends ObjectType> = Overwrite<T, Record<keyof PickByValue<T, ObjectType>, undefined>>;
export type NotIncluded<Type extends ObjectType, Fields extends keyof Type & string> = Overwrite<Type, Record<Fields, undefined>>;
