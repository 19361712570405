import { Injectable } from '@angular/core';

import { LocalStorage } from './local-storage-errors';
import { NonStringPrimitive, StorageAccess } from './storage-access';

@Injectable({ providedIn: 'root' })
export class LocalStorageService implements StorageAccess {
  /**
   * Checks if localStorage is available in the current browser environment.
   * Internally this is done by inserting the test-string `"TEST_STRING"`. This is common practice.
   *
   * Returns `true` if localStorage is available and `false` otherwise.
   */
  isAvailable(): boolean {
    const test = 'TEST_STRING';

    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);

      return true;
    } catch (error) {
      return false;
    }
  }

  private assertKey(key: string): void {
    if (key == null) {
      throw new LocalStorage.NoKeyError();
    }
  }

  private assertKeyValue(key: string, value: unknown): void {
    if (key == null) {
      throw new LocalStorage.NoKeyError();
    }

    if (value == null) {
      throw new LocalStorage.NoValueError();
    }
  }

  /**
   * Writes a non-string primitive value to localStorage.
   * For writing values which are strings already and doesn't require parsing, use {@link writeString}.
   *
   * Any errors that might occur in the process are being caught (except when `key` or `value` are nullish).
   *
   * Returns `true` if the write was successful and `false` otherwise.
   */
  write<T extends NonStringPrimitive>(key: string, value: T): boolean {
    this.assertKeyValue(key, value);

    try {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(key, stringValue);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Writes a string to localStorage.
   * For values which should be parsed to string before being stored, use {@link write}.
   *
   * Any errors that might occur in the process are being caught (except when `key` or `value` are nullish).
   *
   * Returns `true` if the write was successful and `false` otherwise.
   */
  writeString(key: string, value: string): boolean {
    this.assertKeyValue(key, value);

    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Writes a non-string primitive value to localStorage.
   * For writing values which are strings already and doesn't require parsing, use {@link tryWritingString}.
   *
   * Any errors that might occur in the process are being propagated.
   */
  tryWriting<T extends NonStringPrimitive>(key: string, value: T): void {
    this.assertKeyValue(key, value);

    try {
      const valueAsString = JSON.stringify(value);
      localStorage.setItem(key, valueAsString);
    } catch (error) {
      if (error instanceof TypeError) {
        throw new LocalStorage.StringificationError(key, value);
      }

      if (error instanceof DOMException) {
        throw new LocalStorage.StorageUnavailableError();
      }
    }
  }

  /**
   * Writes a string to localStorage.
   * For values which should be parsed to string before being stored, use {@link tryWriting}.
   *
   * Any errors that might occur in the process are being propagated.
   */
  tryWritingString(key: string, value: string): void {
    this.assertKeyValue(key, value);

    try {
      localStorage.setItem(key, value);
    } catch (error) {
      if (error instanceof TypeError) {
        throw new LocalStorage.StringificationError(key, value);
      }

      if (error instanceof DOMException) {
        throw new LocalStorage.StorageUnavailableError();
      }
    }
  }

  /**
   * Reads a non-string primitive value from localStorage.
   * For reading string values (which should not be parsed), use {@link readString}.
   *
   * Any errors that might occur in the process are being caught (except when `key` is nullish).
   *
   * If an error occurs or no value for the given key exists, the fallback value is returned.
   */
  read<T extends NonStringPrimitive, TFallback = T>(key: string, fallback: TFallback): TFallback | T {
    this.assertKey(key);

    try {
      const valueAsString = localStorage.getItem(key);
      return valueAsString ? JSON.parse(valueAsString) : fallback;
    } catch (error) {
      return fallback;
    }
  }

  /**
   * Reads a string value from localStorage.
   * For string values which should be parsed, use {@link read}.
   *
   * Any errors that might occur in the process are being caught (except when `key` is nullish).
   *
   * If an error occurs or no value for the given key exists, the fallback value is returned.
   */
  readString<TFallback extends string | null | undefined>(key: string, fallback: TFallback): string | TFallback {
    this.assertKey(key);

    try {
      const stringValue = localStorage.getItem(key);
      return stringValue !== null ? stringValue : fallback;
    } catch (error) {
      return fallback;
    }
  }

  /**
   * Reads a non-string primitive value from localStorage.
   * For reading string values (which should not be parsed), use {@link tryReadingString}.
   *
   * Any errors that might occur in the process are being propagated.
   *
   * If no value for the given key exists, `null` is returned.
   */
  tryReading<T extends NonStringPrimitive>(key: string): T | null {
    this.assertKey(key);

    try {
      const valueAsString = localStorage.getItem(key);
      return valueAsString !== null ? JSON.parse(valueAsString) : null;
    } catch (error) {
      if (error instanceof DOMException) {
        throw new LocalStorage.StorageUnavailableError();
      }

      throw new LocalStorage.UnknownError();
    }
  }

  /**
   * Reads a string value from localStorage.
   * For string values which should be parsed, use {@link tryReading}.
   *
   * Any errors that might occur in the process are being propagated.
   *
   * If no value for the given key exists, `null` is returned.
   */
  tryReadingString(key: string): string | null {
    this.assertKey(key);

    try {
      return localStorage.getItem(key);
    } catch (error) {
      if (error instanceof DOMException) {
        throw new LocalStorage.StorageUnavailableError();
      }

      throw new LocalStorage.UnknownError();
    }
  }

  /**
   * Removes a value from localStorage.
   *
   * Any errors that might occur in the process are being caught (except when `key` is nullish).
   */
  removeEntry(key: string): boolean {
    this.assertKey(key);

    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Removes a value from localStorage.
   *
   * Any errors that might occur in the process are being propagated.
   */
  tryRemovingEntry(key: string): void {
    this.assertKey(key);

    try {
      localStorage.removeItem(key);
    } catch (error) {
      if (error instanceof DOMException) {
        throw new LocalStorage.StorageUnavailableError();
      }

      throw new LocalStorage.UnknownError();
    }
  }

  /**
   * Checks if an entry with the given key exists in localStorage.
   *
   * Any errors that might occur in the process are being caught (except when `key` is nullish).
   */
  hasEntry(key: string): boolean | null {
    this.assertKey(key);

    try {
      return localStorage.getItem(key) !== null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Checks if an entry with the given key exists in localStorage.
   *
   * Any errors that might occur in the process are being propagated.
   */
  tryCheckingEntry(key: string): boolean {
    this.assertKey(key);

    try {
      return localStorage.getItem(key) !== null;
    } catch (error) {
      if (error instanceof DOMException) {
        throw new LocalStorage.StorageUnavailableError();
      }

      throw new LocalStorage.UnknownError();
    }
  }
}
