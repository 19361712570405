import { Pipe, PipeTransform } from '@angular/core';

import { DateTime, DateTimeFormatOptions, Duration, DurationLikeObject, Interval, ToRelativeOptions } from 'luxon';
import { DatePipeIntl } from './date-pipe-intl';

export type HumanizeRelativeDateParams = {
  whenInRange: DurationLikeObject | undefined,
  fallbackFormat: DateTimeFormatOptions | undefined,

  relativeTo: Date | string | null | undefined,
  unit: ToRelativeOptions['unit'],
};

@Pipe({ name: 'humanizeRelativeDate' })
export class HumanizeRelativeDatePipe implements PipeTransform {

  constructor (private readonly intl: DatePipeIntl) { }

  transform(
    value: Date | string | null | undefined,
    props: Partial<HumanizeRelativeDateParams> = {}
  ): string | undefined {
    if (!value) { return undefined; }

    const { whenInRange, relativeTo, unit, fallbackFormat } = this.mergeWithDefaultValues(props);
    
    const locale = this.intl.locale;
    const date = this.dateTimeFromInput(value);
    const base = this.dateTimeFromInput(relativeTo);

    const humanized = date.toRelative({ base, locale, unit }) ?? undefined;
    const isInRange = whenInRange ? this.dateIsInRange(date, base, Duration.fromObject(whenInRange)) : true;

    return isInRange ? humanized : date.toLocaleString(fallbackFormat, { locale });
  }

  private dateTimeFromInput(value: Date | string | null | undefined): DateTime {    
    const date = typeof value === 'string' ?
      DateTime.fromISO(value) :
      DateTime.fromJSDate(value ?? new Date());

    return date;
  }

  private dateIsInRange(dateToCheck: DateTime, relativeTo: DateTime, duration: Duration): boolean {
    return Interval
      .fromDateTimes(relativeTo.minus(duration), relativeTo)
      .contains(dateToCheck);
  }

  private mergeWithDefaultValues(inputParams: Partial<HumanizeRelativeDateParams>): HumanizeRelativeDateParams {
    const defaultParams: HumanizeRelativeDateParams = {
      whenInRange: undefined,
      relativeTo: undefined,
      fallbackFormat: { ...DateTime.DATE_SHORT, day: '2-digit', month: '2-digit' },
      unit: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
    };

    return { ...defaultParams, ...inputParams };
  }
}

// <div>{ date | humanizeRelativeDate }</div>