import { Pipe, PipeTransform } from '@angular/core';

const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

@Pipe({
  name: 'isUUID',
})

export class IsUUIDPipe implements PipeTransform {
  transform(str: string | undefined): boolean {
    return str?.length ? regexExp.test(str) : false;
  }
}

