import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { of } from 'rxjs';
import { delayWhen, first, tap } from 'rxjs/operators';

import { observeElementInViewport } from '../../observe-element-in-viewport/observe-element-in-viewport';
import { ScrollPositionService } from '../../scroll-position-service';
import { RestoreScrollingTriggerService } from '../services';

@Directive({
  selector: '[mpRestoreScrolling]',
  standalone: true,
  providers: [RestoreScrollingTriggerService],
})
export class RestoreScrollingDirective implements OnDestroy {
  @Input() itemKey = '';

  /**
   * Switches scroll restoring mode from immediate to reacting to a trigger from the RestoreScrollingTriggerDirective directive
   */
  @Input() hasLazyTrigger = false;

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly scrollPositionService: ScrollPositionService,
    private readonly restoreScrollingTriggerService: RestoreScrollingTriggerService,
  ) {
    observeElementInViewport(this.host.nativeElement, [0])
      .pipe(
        delayWhen(() =>
          this.hasLazyTrigger ? this.restoreScrollingTriggerService.scrollingTrigger$.pipe(first()) : of(true),
        ),
        tap(() => this.navigateToScrollPosition()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.scrollPositionService.saveScrollPosition({
      [this.itemKey]: this.host.nativeElement.scrollTop,
    });
  }

  private navigateToScrollPosition(): void {
    this.host.nativeElement.scrollTo(0, this.scrollPositionService.scrollPositions[this.itemKey]);
  }
}
