import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ArrayEntryBuilderManager } from './array-entry-builder-manager';

type ControlFactory = (value?: unknown) => AbstractControl;

export type FillMethod = 'set' | 'patch';

export class FormFiller {

  constructor(private readonly arrayEntryBuilderManager: ArrayEntryBuilderManager) { }

  setArrayValue<T>(array: UntypedFormArray, values: Array<T>, path: string, emitEvent = true): void {
    this.fillFormArray(array, values, path, 'set', emitEvent);
  }

  patchArrayValue(array: UntypedFormArray, values: Array<unknown>, path: string, emitEvent = true): void {
    this.fillFormArray(array, values, path, 'patch', emitEvent);
  }

  setValue(control: AbstractControl, value: unknown, emitEvent = true): void {
    this.fillValue(control, value, 'set', null, emitEvent);
  }

  patchValue(control: AbstractControl, value: unknown, emitEvent = true): void {
    this.fillValue(control, value, 'patch', null, emitEvent);
  }

  fillValue(
    control: AbstractControl,
    value: any,
    method: FillMethod,
    path: string | null = null,
    emitEvent = true
  ): void {
    this.fillAbstractControl(control, value, path, method, emitEvent);
    control.updateValueAndValidity({ emitEvent });
  }

  private fillAbstractControl(
    control: AbstractControl,
    value: any,
    path: string | null,
    method: FillMethod,
    emitEvent: boolean
  ): any {
    if (control instanceof UntypedFormControl) {
      this.fillFormControl(control, value, path, method, emitEvent); // This ends recursion
    } else if (control instanceof UntypedFormGroup) {
      this.fillFormGroup(control, value, path, method, emitEvent); // This potentially continues recursion
    } else if (control instanceof UntypedFormArray) {
      this.fillFormArray(control, value, path, method, emitEvent); // This potentially continues recursion
    }
  }

  private fillFormControl(
    control: UntypedFormControl,
    value: any,
    path: string | null,
    method: FillMethod,
    emitEvent: boolean
  ): void {
    if (value === undefined) {
      if (method === 'set') {
        throw Error(`Value contains no field for control with path "${ path ?? '' }"!`);
      }

      return;
    }

    if (method === 'set') {
      control.setValue(value, { emitEvent });
    } else {
      control.patchValue(value, { emitEvent });
    }
  }

  private fillFormArray(
    array: UntypedFormArray,
    values: Array<any> | null | undefined,
    path: string | null,
    method: FillMethod,
    emitEvent: boolean
  ): void {
    if (values === null || values === undefined) {
      if (method === 'set') {
        throw Error(`Value "${ String(values) }" cannot be fit into a form array! Path: "${ path }"`);
      }

      return;
    }

    values.forEach((value, index) => {
      const hasEntryAtIndex = !!array.controls[index];

      if (hasEntryAtIndex) {
        const entryToFill = array.controls[index];
        this.fillAbstractControl(entryToFill, value, path, method, emitEvent);
      } else {
        const arrayHasEntryBuilderRegistered = this.arrayEntryBuilderManager.hasBuilder(path ?? undefined);

        if (arrayHasEntryBuilderRegistered) {
          const builder = this.arrayEntryBuilderManager.getBuilder(path ?? undefined) as ControlFactory;
          const entryToFill = builder();

          this.fillAbstractControl(entryToFill, value, path, method, emitEvent);
          array.push(entryToFill, { emitEvent });
        }
      }
    });
  }

  private fillFormGroup(
    control: UntypedFormGroup,
    value: any,
    path: string | null,
    method: FillMethod,
    emitEvent: boolean
  ): void {
    if (!value) {
      if (method === 'set') {
        throw Error(`Value "${ String(value) }" cannot be fit into a form group! Path: "${ path }"`);
      }

      return;
    }

    for (const [name, nestedControl] of Object.entries(control.controls)) {
      const currentPath = path ? `${ path }.${ name }` : name;
      const nestedValue = value[name];

      this.fillAbstractControl(nestedControl, nestedValue, currentPath, method, emitEvent);
    }
  }

}
