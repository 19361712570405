import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UrlService {
    private _previousUrl$ = new BehaviorSubject(undefined as undefined | string);
    public previousUrl$: Observable<string | undefined> = this._previousUrl$.asObservable();

    setPreviousUrl(previousUrl: string) {
      this._previousUrl$.next(previousUrl);
  }
}
