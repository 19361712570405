import { Injectable } from '@angular/core';

@Injectable()
export abstract class DatePipeIntl {
  abstract locale: string;
}

@Injectable()
export class DatePipeIntlGerman extends DatePipeIntl {
  locale = 'DE';
}