import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';

import { findControl, findValue, toStore } from '../utils/builders';
import { ArrayEntryBuilderManager } from '../utils/array-entry-builder-manager';
import { ControlSnapshot } from './control-snapshot';
import { Form } from './form';
import { FormEvaluator } from '../utils/form-evaluator';
import { FormFiller } from '../utils/form-filler';
import { FormsStore } from '../utils/forms-store';
import { TypedFormArray } from './typed-form-array';
import { isEqual } from '../utils/is-equal';

/**
 * A typed wrapper for Angular's FormGroup.
 */
export class TypedForm<FormType = any> implements Form<FormType> {

  readonly submittable$: Observable<boolean>;
  private readonly _initialValueChanged$: Observable<boolean>;

  private readonly _initialValue$: BehaviorSubject<FormType | null> = new BehaviorSubject(null as FormType | null);

  readonly arrayEntryBuilderManager: ArrayEntryBuilderManager;
  readonly formFiller: FormFiller;
  readonly formEvaluator: FormEvaluator;

  private autoDestroySubscription?: Subscription;
  private readonly changeSubscription: Subscription;
  private readonly _destroy$ = new Subject<number | string | symbol>();

  get destroy$(): Observable<number | string | symbol> {
    return this._destroy$.asObservable();
  }

  get initialValue(): FormType | null {
    return this._initialValue$.getValue();
  }

  get formGroup(): UntypedFormGroup {
    return this.control as UntypedFormGroup;
  }

  constructor(
    readonly name: number | string | symbol,
    readonly control: AbstractControl,
    private readonly store: FormsStore<any>,
    containsInitialValue = false
  ) {
    this.arrayEntryBuilderManager = new ArrayEntryBuilderManager();
    this.formFiller = new FormFiller(this.arrayEntryBuilderManager);
    this.formEvaluator = new FormEvaluator();

    this.updateStore(name, control);

    this.changeSubscription = this.linkControlChangesWithStore(control);
    this._initialValueChanged$ = this.buildInitialValueChangedObservable();
    this.submittable$ = this.buildSubmittableObservable();

    if (containsInitialValue) {
      this.setInitialValue();
    }
  }

  private linkControlChangesWithStore(control: AbstractControl): Subscription {
    return merge(
      control.valueChanges,
      control.statusChanges.pipe(distinctUntilChanged())
    )
    .subscribe({ next: () => { this.updateStore(this.name, control); }});
  }

  private buildSubmittableObservable(): Observable<boolean> {
    const formIsValid$ = this.validityChanges$();
    const formIsDirty$ = this._initialValueChanged$;

    return combineLatest([
      formIsValid$,
      formIsDirty$
    ]).pipe(
      map(([valid, dirty]) => valid && dirty),
      distinctUntilChanged()
    );
  }

  private buildInitialValueChangedObservable(path?: string): Observable<boolean> {
    return combineLatest([
      this._initialValue$.asObservable(),
      this.valueChanges$()
    ]).pipe(
      map(([initialValue]) => !isEqual(
        this.value(), path ? findValue(initialValue, path) : initialValue
      ))
    );
  }

  /**
   * Returns the validity of the form.
   * @see AbstractControl#valid
   * @example
   * // validity of the form
   * form.isValid();
   */
  isValid(): boolean;
  /**
   * Returns the validity of a control within the form.
   * If no control exists under the given path, `null` is returned.
   * @see AbstractControl#isValid
   * @example
   * // validity of a control within the form
   * form.isValid('email');
   * form.isValid('notiz.content');
   */
  isValid(path: string): boolean;
  isValid(path?: string): boolean {
    return this.snapshot(path)?.valid ?? null;
  }

  /**
   * Returns the value of the form.
   * NOTE: Due to issues with Angulars poor forms implementation and a conflict with our backend,
   *       **the forms value is transformed before being returned**.
   *       This results in differences between {@link value}, {@link valueChanges$} and {@link snapshot}.
   *       This is the current design and might change in the future.
   * @see AbstractControl#value
   * @see FormEvaluator#value
   * @example
   * // value of the form
   * form.value();
   */
  value(): FormType;
  /**
   * Returns the value of a control within the form.
   * NOTE: Due to issues with Angulars poor forms implementation and a conflict with our backend,
   *       **the forms value is transformed before being returned**.
   *       This results in differences between {@link value}, {@link valueChanges$} and {@link snapshot}.
   *       This is the current design and might change in the future.
   * @see AbstractControl#value
   * @see FormEvaluator#value
   * @example
   * // value of a control within the form
   * form.value('email');
   *
   * // values can also be typed
   * form.value<number>('plz');
   */
  value<R = any>(path: string): R;
  value<R = FormType>(path?: string): R {
    if (!path) {
      return this.formEvaluator.value(this.control);
    }

    const control = this.getOrThrowError(path);
    return this.formEvaluator.value(control);
  }

  /**
   * Emits the current dirty state of the form.
   * This is `false` whenever the forms value is equal to the forms initial value
   * and `true` otherwise.
   * NOTE: This will only emit values, when {@link setInitialValue} was called before!
   * @see setInitialValue
   * @example
   * // emits the dirty state of the form
   * form.initialValueChanged$().subscribe(changed => { ... });
   */
  initialValueChanged$(): Observable<boolean>;
  /**
   * Emits the current dirty state of a control within the form.
   * This is `false` whenever the controls value is equal to the controls initial value
   * and `true` otherwise.
   * NOTE: This will only emit values, when {@link setInitialValue} was called before!
   * @see setInitialValue
   * @example
   * // emits the dirty state of a control within the form
   * form.initialValueChanged$('email').subscribe(changed => { ... });
   */
  initialValueChanged$(path: string): Observable<boolean>;
  initialValueChanged$(path?: string): Observable<boolean> {
    return !path ?
      this._initialValueChanged$ :
      this.buildInitialValueChangedObservable(path);
  }

  /**
   * Emits the current validity of the form.
   * @example
   * // emits the validity of the form
   * form.validityChanges$().subscribe(changed => { ... });
   */
  validityChanges$(): Observable<boolean>;
  /**
   * Emits the current validity of a control within the form.
   * @example
   * // emits the validity of the control within the form
   * form.validityChanges$('email').subscribe(changed => { ... });
   */
  validityChanges$(path: string): Observable<boolean>;
  validityChanges$(path?: string): Observable<boolean> {
    return this
      .controlChanges$(path as any)
      .pipe(pluck('valid'));
  }

  /**
   * Emits the value of the form.
   * NOTE: This will emit the *actual* value of a form.
   *           Values emitted are NOT transformed by a FormEvaluator.
   *           (meaning empty strings are not replaced by null)
   * @see AbstractControl#valueChanges
   * @example
   * // emits the value of the form
   * form.valueChanges$().subscribe(changed => { ... });
   */
  valueChanges$(): Observable<FormType>;
  /**
   * Emits the value of a control within the form.
   * NOTE: This will emit the *actual* value of a form.
   *           Values emitted are NOT transformed by a FormEvaluator.
   *           (meaning empty strings are not replaced by null)
   * @see AbstractControl#valueChanges
   * @example
   * // emits the value of a control within the form
   * form.valueChanges$('email').subscribe(changed => { ... });
   */
  valueChanges$<T = any>(path: string): Observable<T>;
  valueChanges$<T = FormType>(path?: string): Observable<T> {
    return this
      .controlChanges$(path as any)
      .pipe(pluck('value')) as any;
  }

  /**
   * Emits the `errors`-object of the form.
   * @see AbstractControl#errors
   * @example
   * // emits the errors of the form
   * formsManager.errorsChanges$().subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(): Observable<Errors>;
  /**
   * Emits the `errors`-object of a control within the form.
   * @see AbstractControl#errors
   * @example
   * // emits the errors of a control within a form
   * form.errorsChanges$('email').subscribe(changed => { ... });
   */
  errorsChanges$<Errors = any>(path: string): Observable<Errors>;
  errorsChanges$<Errors = any>(path?: string): Observable<Errors> {
    return this
      .controlChanges$(path as any)
      .pipe(pluck('errors')) as Observable<Errors>;
  }

  /**
   * Emits the snapshot of the form.
   * NOTE: This observable is fired whenever ANY kind of change occurs (value or state).
   * @see AbstractControl#valueChanges
   * @see AbstractControl#statusChanges
   * @see ControlSnapshot
   * @example
   * // subscribes to any state changes
   * form.controlChanges$().subscribe(snapshot => { ... });
   */
  controlChanges$(): Observable<ControlSnapshot<FormType>>;
  /**
   * Emits the snapshot of a control within the form.
   * NOTE: This observable is fired whenever ANY kind of change occurs (value or state).
   * @see AbstractControl#valueChanges
   * @see AbstractControl#statusChanges
   * @see ControlSnapshot
   * @example
   * // subscribes to any state changes
   * form.controlChanges$('email').subscribe(snapshot => { ... });
   */
  controlChanges$<T = any>(path: string): Observable<ControlSnapshot<T>>;
  controlChanges$<T = FormType>(path?: string): Observable<ControlSnapshot<T>> {
    const controlSnapshot$ = this.store
      .select(state => state[this.name as any])
      .pipe(filter(partialState => partialState !== null && partialState !== undefined));

    if (!path) {
      return controlSnapshot$.pipe(distinctUntilChanged(isEqual));
    }

    return controlSnapshot$.pipe(
      map(snapshot => findControl(snapshot, path)),
      distinctUntilChanged(isEqual)
    );
  }

  /**
   * Gets a specific control from the form.
   * Returns the forms underlying FormGroup if no further path is specified.
   * If no control was registered under the given path, `null` is returned.
   * @see AbstractControl#get
   * @example
   * // gets a control within the form
   * form.get('email');
   */
  get<R extends AbstractControl = AbstractControl>(path?: string): R | null {
    return (path ? this.control.get(path) : this.control) as R;
  }

  private getOrThrowError<T extends AbstractControl = AbstractControl>(path?: string): T {
    const control = this.get(path);

    if (!control) {
      throw Error(`There is no control registered unter the path "${ path }"!`);
    }

    return control as T;
  }

  /**
   * Gets a FormArray from the form and wraps it within a typed wrapper.
   * If no control was registered under the given path, `null` is returned.
   * @see TypedFormArray
   * @example
   * // gets a FormArray from the form and wraps it for convenience
   * const adressen = form.getArray('adressen');
   */
  getArray<T = any>(path: string): TypedFormArray<T> | null {
    return this.has(path) ? new TypedFormArray<T>(path, this as any) : null;
  }

  /**
   * Checks if the form has a control registered under a given path.
   * Returns `true` if a control is registered under that path for that form
   * and `false` otherwise.
   * @example
   * // checks if a control exists within a form
   * form.has('email');
   */
  has(path: string): boolean {
    return !!this.control.get(path);
  }

  /**
   * Registers an "entry-builder"-function with a FormArray within the form.
   * NOTE: This entry-builder is used by the form filler to automatically append
   *       new entries to FormArrays when filling the form.
   * @see FormFiller#fillValue
   * @see ArrayEntryBuilderManager
   * @example
   * // register a new array entry builder
   * const entryBuilder = () => new FormControl(null);
   * form.setArrayEntryBuilder('email', entryBuilder);
   */
  setArrayEntryBuilder(path: string, builder: () => AbstractControl): TypedForm<FormType> {
    const pathExists = !!this.get(path);
    const controlWithPathIsFormArray = this.get(path) instanceof UntypedFormArray;

    if (pathExists && !controlWithPathIsFormArray) {
      throw Error(`The control registered with path "${ path }" is not a form array!`);
    }

    this.arrayEntryBuilderManager.registerBuilder(path, builder);

    return this;
  }
  /**
   * Pushes a new control onto a FormArray.
   * If no control to append is provided, the registered entryBuilder is used.
   * @see setArrayEntryBuilder
   * @example
   * // push new value using a provided control
   * form.pushArray('adressen', new FormControl(null));
   *
   * // push new value with no control provided
   * form.setArrayEntryBuilder('adressen', () => new FormControl(null))
   *     .pushArray('adressen');
   */
  pushArray(path: string, control?: AbstractControl): TypedForm<FormType> {
    const formArray: UntypedFormArray = this.getOrThrowError(path);
    const entryBuilder = this.arrayEntryBuilderManager.getBuilder(path);
    const controlToPush = control ?? (entryBuilder ? entryBuilder() : null);

    if (!controlToPush) {
      throw Error('Neither control nor entry-builder provided for FormArray!');
    }

    formArray?.push(controlToPush);

    return this;
  }

  /**
   * Pushes a new control - filled with the provided value - onto a FormArray within the form.
   * If no control to append is provided, the registered entryBuilder is used.
   * @see setArrayEntryBuilder
   * @example
   * // push new value using a provided control
   * form.pushArrayValue('adressen', '90 Bedford St, New York, NY 10014', new FormControl(null));
   *
   * // push new value with no control provided
   * form.setArrayEntryBuilder('adressen', () => new FormControl(null))
   *     .pushArrayValue('adressen', '90 Bedford St, New York, NY 10014');
   */
  pushArrayValue(path: string, value: any, control?: AbstractControl, method: 'patch' | 'set' = 'patch'): TypedForm<FormType> {
    const formArray: UntypedFormArray = this.getOrThrowError(path);
    const entryBuilder = this.arrayEntryBuilderManager.getBuilder(path);
    const controlToAdd = control ?? (entryBuilder ? entryBuilder() : null);

    if (!controlToAdd) {
      throw Error('Neither control nor entry-builder provided for FormArray!');
    }

    if (method === 'set') {
      controlToAdd.setValue(value);
    } else {
      controlToAdd.patchValue(value);
    }

    formArray?.push(controlToAdd);

    return this;
  }

  /**
   * Removes an entry from a FormArray within the form.
   * @example
   * // remove array entry
   * form.removeArrayIndex('adressen', 2);
   */
  removeArrayIndex(path: string, index: number): TypedForm<FormType> {
    const formArray: UntypedFormArray = this.getOrThrowError(path);
    const controlIsFormArray = formArray instanceof UntypedFormArray;
    if (!formArray || !controlIsFormArray) {
      throw new Error(`The path "${ path }" is invalid or does not contain a FormArray!`);
    }

    if (index < 0 || index >= formArray.length) {
      throw new Error(`No entry existing at index ${ index }!`);
    }

    formArray.removeAt(index);

    return this;
  }

  /**
   * Sets the value of the form.
   * This is done analogously to the Angular implmenentation.
   * @see AbstractControl#setValue
   * @example
   * // sets the value of the form
   * const exactValue = { email: '...', telefon: '...' };
   * form.setValue(exactValue);
   */
  setValue(value: any): TypedForm<FormType>;
  /**
   * Sets the value of a control within the form.
   * This is done analogously to the Angular implementation.
   * @see AbstractControl#setValue
   * @example
   * // sets the value of a control within a form
   * const exactValue = '...';
   * form.setValue(exactValue, 'email');
   */
  setValue(value: any, path: string): TypedForm<FormType>;
  setValue(value: any, path?: string): TypedForm<FormType> {
    const control: AbstractControl = this.getOrThrowError(path);
    this.formFiller.setValue(control, value);

    return this;
  }

  /**
   * Patches the value of the form.
   * This is done analogously to the Angular implementation.
   * @see AbstractControl#patchValue
   * @example
   * // patches the value of the form
   * const valueLike = { email: '...', foo: -3 }
   * form.patchValue(valueLike);
   */
  patchValue(value: any): TypedForm<FormType>;
  /**
   * Patches the value of a control within the form.
   * This is done analogously to the Angular implementation.
   * @see AbstractControl#patchValue
   * @example
   * // patches the value of a control within the form
   * const valueLike = '...';
   * form.patchValue(valueLike, 'email');
   */
  patchValue(value: any, path: string): TypedForm<FormType>;
  patchValue(value: any, path?: string): TypedForm<FormType> {
    const control: AbstractControl = this.getOrThrowError(path);
    this.formFiller.patchValue(control, value);

    return this;
  }

  /**
   * Resets the form.
   * If `toInitialValue` is set, the form is reset to it's initial value.
   * @see AbstractControl#reset
   * @example
   * // reset the form
   * form.reset();
   *
   * // reset the form to it's initial value
   * form.reset('toInitialValue');
   */
  reset(toInitialValue?: 'toInitialValue'): TypedForm<FormType> {
    if (toInitialValue) {
      this.formGroup.reset(this._initialValue$.getValue());
    } else {
      this.formGroup.reset();
    }

    return this;
  }

  /**
   * Sets the initial value of the form using the forms current value.
   * This initial value is used as reference during the change detection.
   * @see TypedForm#initialValueChanged$
   * @example
   * // sets a forms initial value that is used as a baseline during change-detection
   * form.setValue({ email: '...' });
   *
   * form.setInitialValue();
   * // initialValue is now `{ email: '...' }`
   */
  setInitialValue(): TypedForm<FormType> {
    this._initialValue$.next(this.value());

    return this;
  }

  /**
   * Destroys the form.
   * @see TypedForm#destroy
   * @example
   * // destroys the form
   * form.destroy();
   */
  destroy(): void {
    const destroySubscriptionWasNotUnsubscribed =
      this.autoDestroySubscription &&
      !this.autoDestroySubscription.closed;

    if (destroySubscriptionWasNotUnsubscribed) {
      this.autoDestroySubscription?.unsubscribe();
    }

    this.changeSubscription.unsubscribe();

    this._destroy$.next(this.name);
    this._destroy$.complete();
  }

  /**
   * Automatically destroys the form whenever the provided observable emits.
   * NOTE: A manual call to {@link destroy} before the observable emits still causes the form to destroy.
   * @see TypedForm#destroy
   * @example
   * // let the form self-destroy
   * form.destroyWhen(this.isDestroyed$);
   */
  destroyWhen(selfDestruct$: Observable<any>): TypedForm<FormType> {
    this.autoDestroySubscription = selfDestruct$.subscribe({ next: () => { this.destroy(); }});

    return this;
  }

  /**
   * Takes a snapshot of a control within the form.
   * @see ControlSnapshot
   * @example
   * // gets a controls snapshot
   * const snapshot = formManager.snapshot('contact-form', 'email');
   */
  snapshot<T = FormType>(path?: string): ControlSnapshot<T> {
    if (!path) {
      return this.store.getValue()[this.name];
    }

    return findControl(this.snapshot(), path) as any;
  }

  private updateStore(name: number | string | symbol, control: AbstractControl): any {
    const value = toStore<any>(name, control);
    this.store.update({
      [name]: value
    } as any);

    return value;
  }

  /**
   * Updates value and validity of the form.
   * An Angular "options"-object can be passed.
   * NOTE: This will just call the corresponding Angular function.
   *       So any options passed will be passed along to the Angular call.
   * @see AbstractControl#updateValueAndValidity
   * @example
   * // update the forms validity
   * form.updateValueAndValidity();
   * // options can also be passed
   * form.updateValueAndValidity({ onlySelf: true });
   */
  updateValueAndValidity(options: { onlySelf?: boolean, emitEvent?: boolean }): TypedForm<FormType>;
  /**
   * Updates value and validity of a control inside the form.
   * An Angular "options"-object can be passed.
   * If no control exists under the given path, an error is thrown.
   * NOTE: This will just call the corresponding Angular function.
   *       So any options passed will be passed along to the Angular call.
   * @see AbstractControl#updateValueAndValidity
   * @example
   * // update the forms validity
   * form.updateValueAndValidity();
   * // options can also be passed
   * form.updateValueAndValidity({ onlySelf: true });
   */
  updateValueAndValidity(path: string, options?: { onlySelf?: boolean, emitEvent?: boolean }): TypedForm<FormType>;
  updateValueAndValidity(
    path?: string | { onlySelf?: boolean, emitEvent?: boolean },
    options?: { onlySelf?: boolean, emitEvent?: boolean }
  ): TypedForm<FormType> {
    if (typeof path === 'string') {
      const control: AbstractControl = this.getOrThrowError(path);
      control.updateValueAndValidity(options);
    } else {
      this.formGroup.updateValueAndValidity(path);
    }

    return this;
  }

  /**
   * Enables the form.
   * @see AbstractControl#enable
   * @example
   * // enables the form
   * form.enable();
   */
  enable(): TypedForm<FormType>;
  /**
   * Enables a control within the form.
   * @see AbstractControl#enable
   * @example
   * // enables a control
   * form.enable('email');
   */
  enable(path: string): TypedForm<FormType>;
  enable(path?: string): TypedForm<FormType> {
    const control: AbstractControl = this.getOrThrowError(path);
    control.enable();

    return this;
  }

  /**
   * Disables the form.
   * @see AbstractControl#disable
   * @example
   * // disables the form
   * form.disable();
   */
  disable(): TypedForm<FormType>;
  /**
   * Disables a control within the form.
   * @see AbstractControl#disable
   * @example
   * // disables a control
   * form.disable('email');
   */
  disable(path: string): TypedForm<FormType>;
  disable(path?: string): TypedForm<FormType> {
    const control: AbstractControl = this.getOrThrowError(path);
    control.disable();

    return this;
  }

  /**
   * Sets the validators for the form.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the validators to take effect, {@link updateValueAndValidity} needs to be called.
   * @see AbstractControl#setValidators
   * @example
   * // set the forms validators and triggers validation
   * form.setValidators(Validators.required);
   * form.updateValueAndValidity();
   *
   * // multiple validators can be set at once
   * form.setValidators([Validators.required, Validators.maxLength(5)])
   */
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>): TypedForm<FormType>;
  /**
   * Sets the validators for a control within the form.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the validators to take effect, {@link updateValueAndValidity} needs to be called.
   * @see AbstractControl#setValidators
   * @example
   * // sets a controls validators and triggers validation
   * form.setValidators('email', Validators.required);
   * form.updateValueAndValidity('email');
   *
   * // multiple validators can be set at once
   * form.setValidators('email', [Validators.required, Validators.maxLength(5)])
   */
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>, path: string): TypedForm<FormType>;
  setValidators(newValidator: ValidatorFn | Array<ValidatorFn>, path?: string): TypedForm<FormType> {
    const control: AbstractControl = this.getOrThrowError(path);
    control.setValidators(newValidator);

    return this;
  }

  /**
   * Clears all validators for the form.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the clear to take effect, {@link updateValueAndValidity} needs to be called.
   * @see AbstractControl#setValidators
   * @example
   * // clears the forms validators and triggers validation
   * form.setValidators(Validators.required);
   * form.updateValueAndValidity();
   */
  clearValidators(): TypedForm<FormType>;
  /**
   * Clears all validators for a control within the form.
   * NOTE: This will just call the corresponding Angular function.
   * NOTE: For the clear to take effect, {@link updateValueAndValidity} needs to be called.
   * @see AbstractControl#setValidators
   * @example
   * // clears a controls validators and triggers validation
   * form.setValidators('email', Validators.required);
   * form.updateValueAndValidity('email');
   */
  clearValidators(path: string): TypedForm<FormType>;
  clearValidators(path?: string): TypedForm<FormType> {
    const control: AbstractControl = this.getOrThrowError(path);
    control.clearValidators();

    return this;
  }
}
