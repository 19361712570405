import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export class FlyoutManager {
  flyoutCurrentlyOpened = false;
  private afterClosedSubscription?: Subscription;

  markFlyoutAsOpen(): void {
    this.flyoutCurrentlyOpened = true;
  }

  markFlyoutAsClosed(): void {
    this.flyoutCurrentlyOpened = false;
  }

  subscribeToAfterClosed(flyoutRef: { afterClosed$: Observable<unknown> }, callback: () => void): void {
    this.afterClosedSubscription = flyoutRef.afterClosed$.pipe(take(1)).subscribe({
      next: () => {
        callback();
        this.afterClosedSubscription?.unsubscribe();
      },
    });
  }
}
