import { Pipe, PipeTransform } from '@angular/core';

/**
 * Entfernt führende und nachgestellte Anführungszeichen.
 * Alle innerhalb liegende Anführungszeichen werden hierbei ignoriert.
 *
 * **Beispiele:**
 *
 * `'"foo"'` wird zu `'foo'`
 *
 * `'"Ich sagte: "Stop!""'` wird zu `'Ich sagte: "Stop!"'`
 */
@Pipe({ name: 'stripQuotes' })
export class StripQuotesPipe implements PipeTransform {

  transform(value: string): string;
  transform(value: string, quotationMarks: string[]): string;
  transform(value?: string | null): string | undefined;
  transform(value: string | null | undefined, quotationMarks: string[]): string | undefined;

  transform(value?: string | null, quotationMarks = ['"']): string | undefined {
    if (value === undefined || value === null) {
      return undefined;
    }

    function isQuotationMark(char: string): boolean {
      return quotationMarks.includes(char);
    }

    if (value.length > 1) {
      const firstChar = value.charAt(0);
      const lastChar = value.charAt(value.length - 1);
      if (isQuotationMark(firstChar) && isQuotationMark(lastChar)) {
        return value.substring(1, value.length - 1);
      }
    }

    return value;
  }

}
